import { AuthToken } from 'services/common';
import CONFIG from '../config';
import axios from 'axios';

export const addDevice = (deviceData) => {
    return new Promise((resolve, reject) => {
        axios.post(CONFIG.CHAINAPIURL + 'device/create', deviceData, { headers: AuthToken.setHeader() })
            .then(res => {
                let user = res.data;
                if (user.code === 201) {
                    resolve({ success: true, uilog: user.uilog });
                } else {
                    resolve({ success: false, uilog: user.uilog, error: user.message });
                }
            }).catch(err => {
                reject({
                    error: err.response !== undefined ? err.response.data.err : "Server error"
                });
            });
    });
};

function objectEmpty(data) {
    return !Object.keys(data).length;
}

// export const getDevices = (currentPage, limit, searchText) => {
//     return new Promise((resolve, reject) => {
//         setTimeout(() => {
//             let pageNo = (currentPage + 1);
//             // axios.get(CONFIG.APIURL + 'device/getDeviceList?pageNo=' + pageNo + '&limit=' + limit + '&searchText=' + searchText, {headers: AuthToken.setHeader()})
//             axios.get(CONFIG.APIURL + 'device/getDeviceList?pageNo=' + pageNo + '&limit=' + limit + '&searchText=' + searchText, { headers: AuthToken.setHeader() })
//                 .then(res => {
//                     let user = res.data;
//                     if (!objectEmpty(user) && user.code === 200) {
//                         resolve({
//                             devices: user.data.deviceList || [],
//                             devicesTotal: user.data.totalPage || 0
//                         });
//                     } else {
//                         resolve({ devices: [], devicesTotal: 0, error: user.message });
//                     }
//                 }).catch(err => {
//                     resolve({ devices: [], devicesTotal: 0, error: err });
//                 });
//         }, 500);
//     });
// };
export const getDevices = (currentPage, limit, searchText, companyid) => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            let pageNo = (currentPage + 1);
            // axios.get(CONFIG.APIURL + 'device/getDeviceList?pageNo=' + pageNo + '&limit=' + limit + '&searchText=' + searchText, {headers: AuthToken.setHeader()})
            axios.get(CONFIG.CHAINAPIURL + 'device/getDeviceList?pageNo=' + pageNo + '&limit=' + limit + '&searchText=' + searchText + '&companyid=' + companyid, { headers: AuthToken.setHeader() })
                .then(res => {
                    let user = res.data;
                    if (!objectEmpty(user) && user.code === 200) {
                        resolve({
                            devices: user.data.deviceList || [],
                            devicesTotal: user.data.totalPage || 0
                        });
                    } else {
                        resolve({ devices: [], devicesTotal: 0, error: user.message });
                    }
                }).catch(err => {
                    resolve({ devices: [], devicesTotal: 0, error: err });
                });
        }, 500);
    });
};
export const getDeviceDetails = (transactionId, all_purchase_sold) => {
    return new Promise((resolve, reject) => {
        let flag = false;
        if (localStorage.getItem('searchDevice') == 'true') {
            flag = true
        }

        axios.get(CONFIG.CHAINAPIURL + 'device/detail/' + transactionId + "/" + all_purchase_sold + '?&searchDevice=' + flag, { headers: AuthToken.setHeader() })
            .then(res => {
                let user = res.data;
                if (user.code === 200) {
                    let info = user.data;
                    resolve({ success: true, deviceInfo: info });
                } else {
                    resolve({ success: false, deviceInfo: {}, error: user.message });
                }
            }).catch(err => {
                reject({
                    error: err.response !== undefined ? err.response.data.err : "Server error"
                });
            });
    });
};
// export const getDeviceDetails = (transactionId) => {
//     return new Promise((resolve, reject) => {
//         let flag = false;
//         if (localStorage.getItem('searchDevice') == 'true') {
//             flag = true
//         }

//         axios.get(CONFIG.APIURL + 'device/detail/' + transactionId + '?&searchDevice=' + flag, { headers: AuthToken.setHeader() })
//             .then(res => {
//                 let user = res.data;
//                 if (user.code === 200) {
//                     let info = user.data;
//                     resolve({ success: true, deviceInfo: info });
//                 } else {
//                     resolve({ success: false, deviceInfo: {}, error: user.message });
//                 }
//             }).catch(err => {
//                 reject({
//                     error: err.response !== undefined ? err.response.data.err : "Server error"
//                 });
//             });
//     });
// };

export const getDistributor = () => {
    return new Promise((resolve, reject) => {
        axios.get(CONFIG.APIURL + 'user/sellerList', { headers: AuthToken.setHeader() })
            .then(res => {
                let user = res.data;
                if (user.code === 200) {
                    resolve({ success: true, list: user.data });
                } else {
                    resolve({ success: false, list: [], error: user.message });
                }
            }).catch(err => {
                reject({
                    error: err.response !== undefined ? err.response.data.err : "Server error"
                });
            });
    });
};
export const sellDistributor = (sellInfo) => {
    return new Promise((resolve, reject) => {
        axios.post(CONFIG.APIURL + 'device/sell', sellInfo, { headers: AuthToken.setHeader() })
            .then(res => {
                let user = res.data;
                if (user.code === 201) {
                    resolve({ success: true });
                } else {
                    resolve({ success: false, error: user.message });
                }
            }).catch(err => {
                reject({
                    error: err.response !== undefined ? err.response.data.err : "Server error"
                });
            });
    });
};
export const serachDevices = (currentPage, limit, searchText) => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            let pageNo = (currentPage + 1);
            let companyid = CONFIG.COMPANYID;
            axios.get(CONFIG.CHAINAPIURL + 'device/serachDevice?pageNo=' + pageNo + '&limit=' + limit + '&searchText=' + searchText + '&companyid=' + companyid, { headers: AuthToken.setHeader() })
                .then(res => {
                    let user = res.data;
                    if (!objectEmpty(user) && user.code === 200) {
                        resolve({
                            devices: user.data.deviceList || [],
                            devicesTotal: user.data.totalPage || 0
                        });
                    } else {
                        resolve({ devices: [], devicesTotal: 0, error: user.message });
                    }
                }).catch(err => {
                    resolve({ devices: [], devicesTotal: 0, error: err });
                });
        }, 500);
    });
};
// export const serachDevices = (currentPage, limit, searchText) => {
//     return new Promise((resolve, reject) => {
//         setTimeout(() => {
//             let pageNo = (currentPage + 1);

//             axios.get(CONFIG.APIURL + 'device/serachDevice?pageNo=' + pageNo + '&limit=' + limit + '&searchText=' + searchText, { headers: AuthToken.setHeader() })
//                 .then(res => {
//                     let user = res.data;
//                     if (!objectEmpty(user) && user.code === 200) {
//                         resolve({
//                             devices: user.data.deviceList || [],
//                             devicesTotal: user.data.totalPage || 0
//                         });
//                     } else {
//                         resolve({ devices: [], devicesTotal: 0, error: user.message });
//                     }
//                 }).catch(err => {
//                     resolve({ devices: [], devicesTotal: 0, error: err });
//                 });
//         }, 500);
//     });
// };
export const getPurchaseDevices = (currentPage, limit, ackSoldDevice, searchText, companyid) => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            let pageNo = (currentPage + 1);
            axios.post(CONFIG.CHAINAPIURL + 'device/getPurchaserList?pageNo=' + pageNo + '&limit=' + limit + '&searchText=' + searchText + '&companyid=' + companyid, { ackSoldDevice }, { headers: AuthToken.setHeader() })
                .then(res => {
                    let user = res.data;
                    if (!objectEmpty(user) && user.code === 200) {
                        resolve({
                            devices: user.data.deviceList || [],
                            devicesTotal: user.data.totalPage || 0
                        });
                    } else {
                        resolve({ devices: [], devicesTotal: 0, error: user.message });
                    }
                }).catch(err => {
                    resolve({ devices: [], devicesTotal: 0, error: err });
                });
        }, 500);
    });
};
// export const getPurchaseDevices = (currentPage, limit, ackSoldDevice, searchText) => {
//     return new Promise((resolve, reject) => {
//         setTimeout(() => {
//             let pageNo = (currentPage + 1);
//             axios.post(CONFIG.APIURL + 'device/getPurchaserList?pageNo=' + pageNo + '&limit=' + limit + '&searchText=' + searchText, { ackSoldDevice }, { headers: AuthToken.setHeader() })
//                 .then(res => {
//                     let user = res.data;
//                     if (!objectEmpty(user) && user.code === 200) {
//                         resolve({
//                             devices: user.data.deviceList || [],
//                             devicesTotal: user.data.totalPage || 0
//                         });
//                     } else {
//                         resolve({ devices: [], devicesTotal: 0, error: user.message });
//                     }
//                 }).catch(err => {
//                     resolve({ devices: [], devicesTotal: 0, error: err });
//                 });
//         }, 500);
//     });
// };

export const deleteDevices = (devices) => {
    return new Promise((resolve, reject) => {
        axios.post(CONFIG.CHAINAPIURL + 'device/delete', { _id: devices }, { headers: AuthToken.setHeader() })
            .then(res => {
                let user = res.data;
                if (user.code === 205) {
                    resolve({ success: true, data: user.data });
                } else {
                    resolve({
                        devices: [],
                        devicesTotal: 0,
                        success: false
                    });
                }
            }).catch(err => {
                resolve({ devices: [], devicesTotal: 0, error: err });
            });
    });
};
export const getNotification = () => {
    return new Promise((resolve, reject) => {
        axios.get(CONFIG.CHAINAPIURL + 'device/getNotification?companyid=' + CONFIG.COMPANYID, { headers: AuthToken.setHeader() })
            .then(res => {
                let notification = res.data;
                if (notification.code === 200) {
                    resolve({ success: true, notificationList: notification.data });
                } else {
                    resolve({ success: false, notificationList: [], error: notification.message });
                }
            }).catch(err => {
                reject({
                    error: err.response !== undefined ? err.response.data.err : "Server error"
                });
            });
    });
}
// export const getNotification = () => {
//     return new Promise((resolve, reject) => {
//         axios.get(CONFIG.APIURL + 'device/getNotification', { headers: AuthToken.setHeader() })
//             .then(res => {
//                 let notification = res.data;
//                 if (notification.code === 200) {
//                     resolve({ success: true, notificationList: notification.data });
//                 } else {
//                     resolve({ success: false, notificationList: [], error: notification.message });
//                 }
//             }).catch(err => {
//                 reject({
//                     error: err.response !== undefined ? err.response.data.err : "Server error"
//                 });
//             });
//     });
// }

export const dashboardReports = (fromDate, toDate) => {

    return new Promise((resolve, reject) => {
        axios.post(CONFIG.APIURL + 'device/getDashboardReport', { fromDate, toDate }, { headers: AuthToken.setHeader() })
            .then(res => {
                let reports = res.data;
                if (reports.code === 200) {
                    resolve({ success: true, dashboardDetails: reports.data });
                } else {
                    resolve({ success: false, dashboardDetails: [], error: reports.message });
                }
            }).catch(err => {
                reject({
                    error: err.response !== undefined ? err.response.data.err : "Server error"
                });
            });
    });
}

export const lineChartDetail = (year) => {
    return new Promise((resolve, reject) => {
        axios.post(CONFIG.APIURL + 'device/getLineChartDetail', { year }, { headers: AuthToken.setHeader() })
            .then(res => {
                let reports = res.data;
                if (reports.code === 200) {
                    resolve({ success: true, lineChartDetails: reports.data });
                } else {
                    resolve({ success: false, lineChartDetails: [], error: reports.message });
                }
            }).catch(err => {
                reject({
                    error: err.response !== undefined ? err.response.data.err : "Server error"
                });
            });
    });
}