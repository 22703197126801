import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Grid } from '@material-ui/core';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

//custome Components
import Dashboard from './components/Dashboard';


import styles from './styles';

class AdminDashboard extends Component {

    state = { tabIndex: 0};

    render() {
        const { classes } = this.props;

        return (
            <DashboardLayout title="Dashboard">
                <div className={classes.root}>
                    <Dashboard />
                </div>
            </DashboardLayout>
        );
    }
}

AdminDashboard.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AdminDashboard);
