export default {
  fullName: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 25
    }
  },
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      minimum:4,
      maximum: 10
    }
  },
  mobileNumber: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      minimum:10,
      maximum: 14
    },
    format : {
     pattern: /^[0-9A-Fa-f]+$/,
     message: "should be in hexa format"
   }
  },
  // companyName: {
  //   presence: { allowEmpty: false, message: 'is required' },
  //   length: {
  //     maximum: 32
  //   }
  // },
  address: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 40
    }
  },
  policy:{
    presence: { allowEmpty: false, message: 'is required' },
    checked: true
  }
};
