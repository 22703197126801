import { height, textAlign } from "@material-ui/system";

export default theme => ({
  root: {
    padding: theme.spacing.unit * 1,
    width:'auto !important'
  },
  themePadding2: {
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing.unit * 1.5
    }
  },
  soldButton: {
    float: 'right',
  },
  dialogTitle:{
    maxWidth:'100% !important',
    padding:theme.spacing(1)
  },
  title:{
    fontFamily: 'Signika',
    fontWeight: '900',
  },
  stepperTitle:{
    color:'#9A7D0A',
    fontWeight: 800,
    fontSize:'16px'
  },
  captiontext:{
    color:'black'
  },
  historyroot:{
    textAlign:'left',
    margin: '0px',
    marginTop: '15px'
  },
  imageicon:{
    height:'50px',
    width:'50px',
    marginLeft: '-13px'

  },
  bold: {
    fontWeight: 600
  },
  mTop2: {
    marginTop: theme.spacing(2)
  },
  stepperSteps:{
    marginTop: '-11px',
    marginBottom: '-7px'
  },
  scrollWidth:{
    height:'70px'
  }
});
