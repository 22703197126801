import React from 'react';
import { OutlinedInput, InputLabel, FormHelperText, FormControl, Select, withStyles } from '@material-ui/core';
import styles from './styles';
import PropTypes from 'prop-types';
import UserList from 'views/UserList';

const SellerDropdown = props => {
    const { classes, onChange } = props;
    const [values, setValues] = React.useState({
    });

    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
    }, []);
    return (
        <form className={classes.root} autoComplete="off">

            <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel ref={inputLabel} htmlFor="outlined-age-simple">
                    {props.label}
                </InputLabel>
                <Select native
                    onChange={onChange}
                    input={<OutlinedInput labelWidth={labelWidth} name="manufacture" id="outlined-age-simple" />}
                    value={(props.selectedOption && props.selectedOption.value)}
                >
                    <option value={props.defaultValue}>{props.defaultValue}</option>
                    {props.userList.map((user, index) => (
                        <option key={index} value={user}>{user}</option>
                    ))}
                </Select>
            </FormControl>

        </form>
    );
};

SellerDropdown.propTypes = {
    onChange: PropTypes.func,
};

SellerDropdown.defaultProps = {
    onChange: () => { },
};

export default withStyles(styles)(SellerDropdown);