import React, { Component,createRef, useRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';
import DonutChart from 'react-donut-chart';
import styles from './styles';
import Paper from '@material-ui/core/Paper';
import ReactExport from 'react-data-export';
import {Button} from '@material-ui/core'
import {dashboardReports,lineChartDetail} from 'services/product';
import CardMedia from '@material-ui/core/CardMedia';
import { LineChart,PieChart } from 'react-chartkick'
import { Animation } from '@devexpress/dx-react-chart';
import Datepicker from '../../../../components/Datepicker';
import Dropdown from 'components/Dropdown';
import ReactToPrint from 'react-to-print';



const componentRef = createRef();


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelSheet;

class Dashboard extends Component {
    signal = true;
    constructor(props) {
        super(props);
        this.state = {
            years:[],
            isLoading: false,
            open:false,
            dashboardDetails: {
                userList:{},
                deviceList:{}
            },
            lineChartDetails: {
                userList:{}
            },
            isValid:false,
            values:{
                selectedFromDate:'',
                selectedToDate:'',
                selectedYear:(new Date()).getFullYear()
            }
        };
    }
    componentDidMount() {
        this.getDashboardDetail();
        this.handleFieldChange = this.handleFieldChange.bind(this);
        const startYear = 2019;
        const currentYear = (new Date()).getFullYear();
        const yearDiff = currentYear - startYear;
        const years = [];
        for (let i = 0; i <= yearDiff; i++) {
            years.push({year: startYear+i});
        }
        this.setState({years});
    }
    handleFieldChange = (field, value) => {
        const newState = { ...this.state };
        newState.values[field] = value;
        this.setState({newState});
        let fromDate = this.state.values.selectedFromDate;
        let toDate = this.state.values.selectedToDate;

        if(fromDate != '' && toDate!= ''){
            this.setState({isValid:false});
            fromDate = new Date(fromDate);
            toDate = new Date(toDate);
            if(fromDate <= toDate){
                this.setState({isValid:true});
                if(field === 'selectedYear'){
                    this.getDashboardDetail();
                }
            }else{
                this.getDashboardDetail();
            }
        } else{
            this.getDashboardDetail();
        }
    };
    async getDashboardDetail(){
        try {
            this.setState({ isLoading: true });
            let fromDate = this.state.values.selectedFromDate;
            let toDate = this.state.values.selectedToDate;
            let year = this.state.values.selectedYear;
            if(toDate != ''){
                toDate = new Date(toDate);
                toDate.setDate(toDate.getDate() + 1);
                toDate = (toDate).toISOString().split('T')[0];
            }
            if((fromDate === '' || toDate === '') || (new Date(fromDate) > new Date(toDate))){
                fromDate = '';
                toDate = '';
            }
            let {dashboardDetails} = await dashboardReports(fromDate,toDate);
            let {lineChartDetails} = await lineChartDetail(year);
            if (this.signal) {
                this.setState({
                    isLoading: false,
                    dashboardDetails,
                    lineChartDetails
                });
            }
        } catch (error) {
            if (this.signal) {
                this.setState({
                    isLoading: false,
                    error
                });
            }
        }
    }

    _renderReport(){
        const {classes} = this.props;
        const {dashboardDetails,lineChartDetails} = this.state;
        const dataSet1 = [
            {
                columns: [
                    {title: 'Total Number Of', width: {wpx: 280}},//pixels width
                    {title: 'Total Count', width: {wpx: 100}},
                ],
                data: [
                    ['Registered User', dashboardDetails.userList.total],
                    ['Manufacturers', dashboardDetails.userList.Manufacture],
                    ['Distributors', dashboardDetails.userList.Distributor],
                    ['Retailers', dashboardDetails.userList.Retailer],
                    ['Doctors', dashboardDetails.userList.Doctor],
                    ['End Users', dashboardDetails.userList.EndUser],
                    ['',''],
                    ['Created Devices', dashboardDetails.deviceList.total?dashboardDetails.deviceList.total:0],
                    ['Newly Created Devices', dashboardDetails.deviceList.newDevice?dashboardDetails.deviceList.newDevice:0],
                    ['Sold Devices', dashboardDetails.soldList],
                ]
            }
        ];

        for(let x in lineChartDetails.userList){
            let userObj = lineChartDetails.userList[x];
            dataSet1[0].data = dataSet1[0].data.concat([['','']]);
            for(let y in userObj){
                let soldDeviceCount = userObj[y];
                dataSet1[0].data = dataSet1[0].data.concat([['Products Sold By '+x[0].toUpperCase() + x.slice(1)+' In '+y,soldDeviceCount]]);
            }
        }
        return(
            <ExcelFile
                element={
                    <div>
                        <Button
                            className={classes.downloadButton}
                            color="primary"
                            variant="contained"
                        >Generate Xls</Button>
                    </div>
                }
            >
                <ExcelSheet
                    dataSet={dataSet1}
                    name="Reports"
                />
            </ExcelFile>
        )
    }

    _renderSellDevice(){
        const {classes} = this.props;
        const {years,lineChartDetails} = this.state;
        const userLists = lineChartDetails.userList;
        return(
            <Paper>
                <Typography className={classes.graphTitle}>
                    SOLD DEVICES
                </Typography>
                <div className={classes.soldDeviceDropdown}>
                    <Dropdown
                        defaultValue={this.state.values.selectedYear}
                        label="Select Year"
                        onChange={event => this.handleFieldChange('selectedYear',event.target.value)}
                        ref={el => (componentRef = el)}
                        userList={years}
                    />
                </div>
                <LineChart
                    data = {[
                        {name:'Manufacturer',data:userLists.manufacture},
                        {name:'Distributor',data:userLists.distributor},
                        {name:'Retailer',data:userLists.retailer},
                        {name:'Doctor',data:userLists.doctor}
                    ]}
                    height="407px"
                    width="100%"
                    id="users-chart"
                    xtitle="Months"
                    ytitle="Number of sold product"
                    // legend="right"
                />
            </Paper>
        )
    }

    render() {
        const { classes } = this.props;
        const {dashboardDetails,isValid,years,lineChartDetails} = this.state;
        return (
            <div className={classes.root}>
                <Grid

                    container
                    spacing={3}
                >
                    <Grid
                        item
                        xs={6}
                        sm={3}
                    >
                        <Paper>
                            <Datepicker
                                label = "From Date"
                                onChange={event => this.handleFieldChange('selectedFromDate',event.target.value)}
                            />
                        </Paper>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        sm={3}
                    >
                        <Paper>
                            <Datepicker
                                label = "To Date"
                                onChange={event => this.handleFieldChange('selectedToDate',event.target.value)}
                            />
                        </Paper>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={2}
                    >
                        <Button
                            className={classes.downloadButton}
                            color="primary"
                            disabled={!isValid}
                            onClick={this.getDashboardDetail.bind(this)}
                            variant="contained"
                        >Search

                        </Button>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        sm={2}
                    >
                        {this._renderReport()}
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        sm={2}
                    >
                        <ReactToPrint
                            content={() => componentRef.current}
                            trigger={() =>

                                <Button
                                    className={classes.downloadButton}
                                    color="primary"
                                    variant="contained"
                                >Generate Pdf</Button>
                            }
                            pageStyle="@page { size: landscape; margin: 5mm 5mm 14mm 12mm;}"
                        />
                    </Grid>

                </Grid>
                <Grid
                    className={classes.cardPadding}
                    container
                    ref={componentRef}
                    spacing={2}
                >
                    <Grid item xs={3} className={classes.dashboardInfo} >
                        <Paper className={classes.card}>
                            <Grid item xs={3} className={classes.contentInfo}>
                                <Typography className={classes.contentTitle}>
                                    TOTAL<br /> DEVICES
                                </Typography>
                                <Typography className={classes.content}>
                                    {dashboardDetails.deviceList.total?dashboardDetails.deviceList.total:0}
                                </Typography>

                            </Grid>
                            <Grid item xs={3} className={classes.contentInfo}>
                                <img
                                    alt="product icon"
                                    className={classes.imageicon}
                                    src="/images/img/product.png"
                                />
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={3} className={classes.dashboardInfo} >
                        <Paper className={classes.card}>
                            <Grid item xs={3} className={classes.contentInfo}>
                                <Typography className={classes.contentTitle}>
                                    NEW <br /> DEVICES
                                </Typography>
                                <Typography className={classes.content}>
                                    {dashboardDetails.deviceList.newDevice?dashboardDetails.deviceList.newDevice:0}
                                </Typography>

                            </Grid>
                            <Grid item xs={3} className={classes.contentInfo}>
                                <img
                                    alt="user icon"
                                    className={classes.imageicon}
                                    src="/images/img/devices.png"
                                />
                            </Grid>
                        </Paper>
                    </Grid>

                    <Grid item xs={3} className={classes.dashboardInfo} >
                        <Paper className={classes.card}>
                            <Grid item xs={3} className={classes.contentInfo}>
                                <Typography className={classes.contentTitle}>
                                    SOLD <br /> DEVICES
                                </Typography>
                                <Typography className={classes.content}>
                                    {dashboardDetails.soldList}
                                </Typography>

                            </Grid>
                            <Grid item xs={3} className={classes.contentInfo}>
                                <img
                                    alt="user icon"
                                    className={classes.imageicon}
                                    src="/images/img/devices.png"
                                />
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={3} className={classes.dashboardInfo}>
                        <Paper className={classes.card}>
                            <Grid item xs={3} className={classes.contentInfo}>
                                <Typography className={classes.contentTitle}>
                                    TOTAL<br />USERS
                                </Typography>
                                <Typography className={classes.content}>
                                    {dashboardDetails.userList.total}
                                </Typography>

                            </Grid>
                            <Grid item xs={3} className={classes.contentInfo}>
                                <img
                                    alt="user icon"
                                    className={classes.imageicon}
                                    src="/images/img/user.png"
                                />
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                    >
                        {this._renderSellDevice()}
                    </Grid>
                    <Grid
                        item
                        xs={6}
                    >
                        <Paper>
                            <Typography className={classes.graphTitle}>
                                USERS COUNT
                            </Typography>
                            <DonutChart
                                className={classes.totalUser}
                                data={[
                                    { label: 'Manufacturer', value:dashboardDetails.userList.Manufacture },
                                    { label: 'Distributor', value:dashboardDetails.userList.Distributor},
                                    { label: 'Retailer', value: dashboardDetails.userList.Retailer},
                                    { label: 'Doctor', value: dashboardDetails.userList.Doctor  },
                                    { label: 'User', value:dashboardDetails.userList.EndUser},
                                ]}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

Dashboard.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Dashboard);