import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { CircularProgress, Typography } from '@material-ui/core';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

// Shared services
import { getUsers,userBlockUnBlock,deleteUsers } from 'services/user';
import {Toast} from 'services/common'
import { ToastContainer } from 'react-toastify';

// Custom components
import {  UsersTable } from './components';
import { Toolbar } from 'components';

// Component styles
import styles from './style';

class UserList extends Component {
  signal = true;

  state = {
    isLoading: false,
    limit: 5,
    users: [],
    selectedUsers: [],
    error: null,
    usersTotal:0,
    currentPage:0,
    searchText:''
  };
  updateUser =this.updateUser.bind(this);
  handleDeleteUsers=this.handleDeleteUsers.bind(this);
  componentDidMount() {
    this.signal = true;
    const { currentPage,limit,searchText } = this.state;
    this.getUsers(currentPage,limit,searchText);
  }

  componentWillUnmount() {
    //alert("helo");
    this.signal = false;
  }
  async getUsers(currentPage,limit,searchText) {
    try {
      this.setState({ isLoading: true });
      searchText= searchText ==undefined ? '':searchText;
      const { users,usersTotal } = await getUsers(currentPage,limit,searchText);

      if (this.signal) {
        this.setState({
          isLoading: false,
          users,
          usersTotal
        });
      }
    } catch (error) {
      if (this.signal) {
        this.setState({
          isLoading: false,
          error
        });
      }
    }
  }
  async updateUser(user,index) {
    try {
      
      const { users } = this.state;

      let data ={
        _id :user._id,
        status:!user.isActive
      }
      let updateUser  = await userBlockUnBlock(data);
      if(updateUser.success && updateUser.info == 1){
        users[index]['isActive']= !user['isActive'];
        this.setState({
          users:users
        })
      }else{
        Toast.errorToast("Your status not update");
      }
    } catch (error) {
      
    }
  }
  async handleDeleteUsers() {
    try {
      const { selectedUsers,currentPage,limit,searchText } = this.state;
      let deleteUser  = await deleteUsers(selectedUsers);
      if(deleteUser.success){
        this.getUsers(currentPage,limit,searchText);
      }else{
        Toast.errorToast("users not deleted");
      }
    } catch (error) {
      
    }
  }
  handleSelect = selectedUsers => {
    this.setState({ selectedUsers });
  };
  handlePageChange = (pageNo,rowPage) => {
    this.setState({ currentPage :pageNo,limit:rowPage });
    this.getUsers(pageNo,rowPage);
  };
  
  onAddUser(){
    const  { history } = this.props;
    history.push('/add-user');
  }
  handleSearch = (searchText,clean) => {
    this.getUsers(0,this.state.limit,searchText);
    this.setState({currentPage:0,searchText:searchText});
  }
  renderUsers() {
    const { classes } = this.props;
    const { isLoading, users,usersTotal,limit,currentPage,searchText, error } = this.state;

    if (isLoading) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      );
    }

    if (error) {
      return <Typography variant="h6">{error}</Typography>;
    }

    if (users.length === 0) {
      return <Typography variant="h6">There are no users</Typography>;
    }

    return (
      <UsersTable
        //
        onSelect={this.handleSelect}
        users={users}
        usersTotal={usersTotal}
        page={currentPage}
        rowsPerPage={limit}
        onPageChange={this.handlePageChange}
        onUpdateUser={this.updateUser}
        onSearch={searchText}
      />
    );
  }

  render() {
    const { classes } = this.props;
    const { selectedUsers } = this.state;

    return (
      <DashboardLayout title="Users">
        <div className={classes.root}>
          <Toolbar selectedUsers={selectedUsers} 
          onAdd={()=>this.onAddUser()}
          onDelete={this.handleDeleteUsers}
          onSearch={this.handleSearch}
          deleteButton={true} addButton={true}/>
          <div className={classes.content}>{this.renderUsers()}</div>
        </div>
        <ToastContainer />
      </DashboardLayout>
    );
  }
}

UserList.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(UserList);
