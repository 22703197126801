import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';

// Externals
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import validate from 'validate.js';
import _ from 'underscore';

// Material helpers
import {withStyles} from '@material-ui/core';

// Material components
import {
    Button,
    Checkbox,
    CircularProgress,
    Grid,
    TextField,
    Typography
} from '@material-ui/core';


// Shared utilities
import validators from 'common/validators';

// Component styles
import styles from './styles';

// Form validation schema
import schema from './schema';
import {signUp} from 'services/user';
import {Toast} from 'services/common'
import {ToastContainer} from 'react-toastify';

// Shared components
import {CardImage} from 'components';

validate.validators.checked = validators.checked;


class SignUp extends Component {
    state = {
        values: {
            userType: 'DISTRIBUTOR',
            fullName: '',
            email: '',
            password: '',
            mobileNumber: '',
            companyName: '',
            address: '',
            policy: false
        },
        touched: {
            fullName: false,
            email: false,
            password: false,
            mobileNumber: false,
            companyName: false,
            address: false,
            policy: null
        },
        errors: {
            fullName: null,
            email: null,
            password: null,
            mobileNumber: null,
            address: null,
            companyName: null,
            policy: null
        },
        isValid: false,
        isLoading: false,
        submitError: null
    };

    handleBack = () => {
        const {history} = this.props;

        history.goBack();
    };

    validateForm = _.debounce(() => {
        const {values} = this.state;

        const newState = {...this.state};
        const errors = validate(values, schema);

        newState.errors = errors || {};
        newState.isValid = errors ? false : true;

        this.setState(newState);
    }, 300);

    handleFieldChange = (field, value) => {
        const newState = {...this.state};

        newState.submitError = null;
        newState.touched[field] = true;
        newState.values[field] = value;

        this.setState(newState, this.validateForm);
    };

    handleSignUp = async () => {
        try {
            const {history} = this.props;
            const {values} = this.state;

            this.setState({isLoading: true});

            let register = await signUp({
                role: values.userType,
                name: values.fullName,
                email: values.email,
                password: values.password,
                mobileNumber: values.mobileNumber,
                company: values.companyName,
                address: values.address,
                isActive: true
            });

            if (register.success === true) {
                Toast.successToast("Email sent to your mail for the account verify");
                // localStorage.getItem('authType') === 'ADMIN' ?
                //   history.push('/users')
                // : history.push('/account');
                this.setState({
                    isLoading: false,
                });
            } else {
                Toast.errorToast(register.error);
                this.setState({
                    isLoading: false,
                    serviceError: register.error
                });
            }
        } catch (error) {
            Toast.errorToast(error.error !== undefined ? error.error : "server error");
            this.setState({
                isLoading: false,
                serviceError: error
            });
        }
    };

    render() {
        const roleList = ['DISTRIBUTOR', 'DOCTOR', 'ENDUSER', 'MANUFACTURER', 'RETAILER']
        const {classes} = this.props;
        const {
            values,
            touched,
            errors,
            isValid,
            submitError,
            isLoading
        } = this.state;

        const showfullNameError =
            touched.fullName && errors.fullName ? errors.fullName[0] : false;
        const showEmailError =
            touched.email && errors.email ? errors.email[0] : false;
        const showPasswordError =
            touched.password && errors.password ? errors.password[0] : false;
        const showMobileNumberError =
            touched.mobileNumber && errors.mobileNumber ? errors.mobileNumber[0] : false;
        const showcompanyNameError =
            touched.companyName && errors.companyName ? errors.companyName[0] : false;
        const showAddressError =
            touched.address && errors.address ? errors.address[0] : false;
        const showPolicyError =
            touched.policy && errors.policy ? errors.policy[0] : false;

        return (
            <div className={classes.root}>
                <Grid
                    className={classes.grid}
                    container
                >
                    <Grid
                        className={classes.quoteWrapper}
                        item
                        lg={5}>
                        <CardImage/>
                    </Grid>
                    <Grid
                        className={classes.content}
                        item
                        lg={7}
                        xs={12}>
                        <div className={classes.content}>
                            <div className={classes.contentBody}>
                                <form className={classes.form}>
                                    <Typography
                                        className={classes.title}
                                        variant="h2">
                                        Create new account
                                    </Typography>
                                    <Typography
                                        className={classes.subtitle}
                                        variant="body1">
                                        Use your work email to create new account... it's free.
                                    </Typography>
                                    <div className={classes.fields}>
                                        <TextField
                                            className={classes.textField}
                                            label="Select User Type"
                                            margin="dense"
                                            onChange={event =>
                                                this.handleFieldChange('userType', event.target.value)
                                            }
                                            required
                                            select
                                            SelectProps={{native: true}}
                                            value={values.userType}
                                            variant="outlined">
                                            {roleList.map(option => (
                                                <option key={option} value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </TextField>

                                        <TextField
                                            className={classes.textField}
                                            label="Full Name"
                                            name="fullName"
                                            onChange={event =>
                                                this.handleFieldChange('fullName', event.target.value)
                                            }
                                            value={values.fullName}
                                            variant="outlined"
                                        />
                                        {showfullNameError && (
                                            <Typography
                                                className={classes.fieldError}
                                                variant="body2"
                                            >
                                                {errors.fullName[0]}
                                            </Typography>
                                        )}
                                        <TextField
                                            className={classes.textField}
                                            label="Email Address"
                                            name="email"
                                            onChange={event =>
                                                this.handleFieldChange('email', event.target.value)
                                            }
                                            value={values.email}
                                            variant="outlined"
                                        />
                                        {showEmailError && (
                                            <Typography
                                                className={classes.fieldError}
                                                variant="body2"
                                            >
                                                {errors.email[0]}
                                            </Typography>
                                        )}
                                        <TextField
                                            className={classes.textField}
                                            label="Password"
                                            onChange={event =>
                                                this.handleFieldChange('password', event.target.value)
                                            }
                                            type="password"
                                            value={values.password}
                                            variant="outlined"
                                        />
                                        {showPasswordError && (
                                            <Typography
                                                className={classes.fieldError}
                                                variant="body2"
                                            >
                                                {errors.password[0]}
                                            </Typography>
                                        )}
                                        <TextField
                                            className={classes.textField}
                                            label="Mobile Number"
                                            name="mobileNumber"
                                            onChange={event =>
                                                this.handleFieldChange('mobileNumber', event.target.value)
                                            }
                                            value={values.mobileNumber}
                                            variant="outlined"
                                        />
                                        {showMobileNumberError && (
                                            <Typography
                                                className={classes.fieldError}
                                                variant="body2"
                                            >
                                                {errors.mobileNumber[0]}
                                            </Typography>
                                        )}
                                        <TextField
                                            className={classes.textField}
                                            label="company Name"
                                            onChange={event =>
                                                this.handleFieldChange('companyName', event.target.value)
                                            }
                                            value={values.companyName}
                                            variant="outlined"
                                        />
                                        {showcompanyNameError && (
                                            <Typography
                                                className={classes.fieldError}
                                                variant="body2"
                                            >
                                                {errors.companyName[0]}
                                            </Typography>
                                        )}
                                        <TextField
                                            className={classes.textField}
                                            label="Complete Address"
                                            name="address"
                                            onChange={event =>
                                                this.handleFieldChange('address', event.target.value)
                                            }
                                            value={values.address}
                                            variant="outlined"
                                        />
                                        {showAddressError && (
                                            <Typography
                                                className={classes.fieldError}
                                                variant="body2"
                                            >
                                                {errors.address[0]}
                                            </Typography>
                                        )}
                                        <div className={classes.policy}>
                                            <Checkbox
                                                checked={values.policy}
                                                className={classes.policyCheckbox}
                                                color="primary"
                                                name="policy"
                                                onChange={() =>
                                                    this.handleFieldChange('policy', !values.policy)
                                                }
                                            />
                                            <Typography
                                                className={classes.policyText}
                                                variant="body1"
                                            >
                                                I have read the &nbsp;
                                                <Link
                                                    className={classes.policyUrl}
                                                    to="#"
                                                >
                                                    Terms and Conditions
                                                </Link>
                                                .
                                            </Typography>
                                        </div>
                                        {showPolicyError && (
                                            <Typography
                                                className={classes.fieldError}
                                                variant="body2"
                                            >
                                                {errors.policy[0]}
                                            </Typography>
                                        )}
                                    </div>
                                    {submitError && (
                                        <Typography
                                            className={classes.submitError}
                                            variant="body2"
                                        >
                                            {submitError}
                                        </Typography>
                                    )}
                                    {isLoading ? (
                                        <CircularProgress className={classes.progress}/>
                                    ) : (
                                        <Button
                                            className={classes.signUpButton}
                                            color="primary"
                                            disabled={!isValid}
                                            onClick={this.handleSignUp}
                                            size="large"
                                            variant="contained"
                                        >
                                            Sign up now
                                        </Button>
                                    )}
                                    <Typography
                                        className={classes.signIn}
                                        variant="body1"
                                    >
                                        Have an account?{' '}
                                        <Link
                                            className={classes.signInUrl}
                                            to="/sign-in"
                                        >
                                            Sign In
                                        </Link>
                                    </Typography>
                                </form>
                            </div>
                        </div>
                    </Grid>
                </Grid>
                <ToastContainer/>
            </div>
        );
    }
}

SignUp.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

export default compose(
    withRouter,
    withStyles(styles)
)(SignUp);
