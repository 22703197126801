import React from 'react';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material helpers
import { withStyles,Typography } from '@material-ui/core';

const styles = theme => ({
  
  quote: {
    backgroundColor: theme.palette.common.neutral,
    height: '70%',
    marginTop:'15%',
    width:'100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/logos/LOGO-2-CMYK.png)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.common.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing.unit * 3,
    color: theme.palette.common.white
  },
  bio: {
    color: theme.palette.common.white
  },
  
});

const CardImage = props => {
  const { classes, className } = props;

  

  return (
      <div className={classes.quote}>
        {/* <img src="/images/logos/LOGO-2-CMYK.png" /> */}
        <div className={classes.quoteInner}>
          <Typography
            className={classes.quoteText}
            variant="h1"
          >
           The HC software is designed to provide blockchain verified traceability for the lifecycle of medical devices
          </Typography>
          <div className={classes.person}>
            <Typography
              className={classes.name}
              variant="body1"
            >
            </Typography>
            <Typography
              className={classes.bio}
              variant="body2"
            >
            </Typography>
          </div>
        </div>
      </div>
  );
};

CardImage.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  
};


export default withStyles(styles)(CardImage);
