import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

// Externals
import { Chart } from 'react-chartjs-2';

// Material helpers
import { ThemeProvider } from '@material-ui/styles';

// ChartJS helpers
import { chartjs } from './helpers';

// Theme
import theme from './theme';

// Styles
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';

// Routes
import Routes from './Routes';
import Auth from './services/common/authToken';
// Browser history
const browserHistory = createBrowserHistory();

// Configure ChartJS
Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw
});

export default class App extends Component {
  constructor(props) {
    super(props);
 
    this.onUnload = this.onUnload.bind(this); // if you need to bind callback to this
 }
 onUnload(event) { // the method that will be used for both add and remove event
  event.preventDefault();
    event.returnValue = "Changes you may not be saved"
 }
 
 componentDidMount() {
  window.addEventListener("beforeunload", this.onUnload)
 }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Router history={browserHistory}>
          <Routes />
        </Router>
      </ThemeProvider>
    );
  }
}
