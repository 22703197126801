import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import _ from 'lodash';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import {
  Badge,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  TableSortLabel
} from '@material-ui/core';

// Shared components
import { Portlet, PortletContent } from 'components';
import theme from "../../theme";

// Component styles
import styles from './styles';

class TableView extends Component {
  state = {
    selectedUsers: [],
    users: this.props.users,
    // sort: {
    //   column: null,
    //   direction: 'desc',
    // },
    sort: {
      order: 1,
      type: 'name'
    }
  };
  handleSelectAll = event => {
    const { users, onSelect } = this.props;

    let selectedUsers;

    if (event.target.checked) {
      selectedUsers = users.map(user => user._id);
    } else {
      selectedUsers = [];
    }

    this.setState({ selectedUsers });

    onSelect(selectedUsers);
  };

  handleSelectOne = (event, id) => {
    const { onSelect } = this.props;
    const { selectedUsers } = this.state;

    const selectedIndex = selectedUsers.indexOf(id);
    let newSelectedUsers = [];

    if (selectedIndex === -1) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers, id);
    } else if (selectedIndex === 0) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(1));
    } else if (selectedIndex === selectedUsers.length - 1) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedUsers = newSelectedUsers.concat(
        selectedUsers.slice(0, selectedIndex),
        selectedUsers.slice(selectedIndex + 1)
      );
    }

    this.setState({ selectedUsers: newSelectedUsers });

    this.props.onSelect(newSelectedUsers);
  };

  handleChangePage = (event, page) => {
    this.props.onPageChange(page, this.props.rowsPerPage);
  };

  handleChangeRowsPerPage = event => {
    this.props.onPageChange(this.props.page, event.target.value);
  };
  updateUsersStatus = (user, index) => {
    this.props.onUpdateUser(user, index, this)
  }
  handleViewPage =(id)=>{
    this.props.onView(id);
  }
  createSortHandler(row) {
    let newSortParams = { ...this.state.sort };
    if (this.state.sort.type === row) {
      newSortParams.order = this.state.sort.order === 0 ? 1 : 0;
    } else {
      newSortParams.type = row;
    }
    let sortOrder = newSortParams.order === 0 ? 'asc' : 'desc';
    let users = _.orderBy(this.props.users, [row], [sortOrder]);
    this.setState({
      sort: newSortParams,
      users: users,
      order: sortOrder
    });
  }

  render() {
    const { classes, className, query,onShowRowHeader,users, usersTotal, rowsPerPage, page, onCheckBox,onSearch } = this.props;
    const { activeTab, selectedUsers, order } = this.state;

    const rootClassName = classNames(classes.root, className);

    let { onSelectAllClick, orderBy, numSelected, rowCount, setOrder, setOrderBy, onRequestSort, handleRequestSort,onViewButton } = this.props;
    return (
      <Portlet className={rootClassName}>
        <PortletContent noPadding>
          <PerfectScrollbar>
            <Table>
              <TableHead>
                <TableRow>

                  {
                    onShowRowHeader.map((user, index) => (
                      user.id =='_id'?null:<TableCell align="left" className={classes.tableCell}>
                        {(user.id === 'name' && onCheckBox ) ?
                          <Checkbox align="left"
                            checked={selectedUsers.length === users.length}
                            color="primary"
                            indeterminate={
                              selectedUsers.length > 0 &&
                              selectedUsers.length < users.length
                            }
                            onChange={this.handleSelectAll}
                          />
                        :null}
                        <TableSortLabel
                          direction={order}
                          onClick={() => this.createSortHandler(user.id)}
                        >{user.label}</TableSortLabel>
                      </TableCell>
                    ))
                  }
                  {onViewButton ?<TableCell align="left" className={classes.tableCell}>
                      View
                  </TableCell>:null}
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.users
                  .map((user, index) => (
                    console.log("2323",user),
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={user._id}
                      selected={selectedUsers.indexOf(user._id) !== -1}
                    >
                      <TableCell className={classes.tableCell}>
                        <div className={classes.tableCellInner}>
                          {onCheckBox? <Checkbox
                            checked={selectedUsers.indexOf(user._id) !== -1}
                            color="primary"
                            onChange={event =>
                              this.handleSelectOne(event, user._id)
                            }
                            value="true"
                          />:null}

                          <Link to="#">
                            <Typography
                              className={classes.nameText}
                              variant="body1"
                            >
                              {user.UDI}
                            </Typography>
                          </Link>
                        </div>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {user.deviceName}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {user.brandName}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {user.companyName}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {user.createdAt}
                      </TableCell>
                      {onViewButton?<TableCell className={classes.tableCell}>
                        <Badge color="secondary" className={classes.badgeSuccess}
                            onClick={() => this.handleViewPage(user.transactionId)}
                          >
                            View
                          </Badge>
                      </TableCell>:null}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </PerfectScrollbar>
          <TablePagination
              className={classes.paginationButton}
              backIconButtonProps={{
                'aria-label': 'Previous Page'
              }}
              component="div"
              count={usersTotal}
              nextIconButtonProps={{
                'aria-label': 'Next Page'
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
              classes={{
                actions: classes.tablePaginationActions,
                selectRoot:classes.tablePaginationSelectRoot
              }}
          />
        </PortletContent>
      </Portlet>
    );
  }
}

TableView.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
  onShowDetails: PropTypes.func,
  users: PropTypes.array.isRequired,
  usersTotal: PropTypes.number,
  onPageChange: PropTypes.func,
  onUpdateUser: PropTypes.func,
  onSearch: PropTypes.string,
  onShowRowHeader: PropTypes.array.isRequired,
  onCheckBox:PropTypes.bool,
  onViewButton:PropTypes.bool,
  onView:PropTypes.func,

};

TableView.defaultProps = {
  users: [],
  onShowRowHeader:[],
  usersTotal: 0,
  rowsPerPage: 5,
  page: 0,
  onSearch: '',
  order: '',
  onPageChange: () => { },
  onSelect: () => { },
  onShowDetails: () => { },
  onUpdateUser: () => { }
};

export default withStyles(styles)(TableView);



