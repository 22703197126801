
export default theme =>({
    root:{
        width:'100%',
    },
    dashboardInfo:{
      marginTop: theme.spacing.unit *2,
        [theme.breakpoints.down('xs')]:{
          marginTop: '12px'
        },
        [theme.breakpoints.down('xs')]:{

        }

    },
    content:{
        padding: theme.spacing.unit *1,
        color:'#22201F',
        fontSize: 35,
        fontWeight:'500',
        textAlign: 'center',
        [theme.breakpoints.down('xs')]:{
            padding:theme.spacing.unit *0.5
        }
    },
    contentTitle:{
        fontWeight: '600',
        color: '#708090',
        textAlign: 'center',
        [theme.breakpoints.down('xs')]:{
            fontSize:'12px'
        }
    },
    imageicon:{
       width:'100%',
        height:'auto',
        [theme.breakpoints.down('xs')]:{
           display: 'none',
        }
    },
    deliveryImageIcon:{
        paddingTop:theme.spacing.unit *1,
        paddingLeft:theme.spacing.unit *1,
        width:'70px',
    },
    card:{
        display:'flex',
        // padding:'10px'
    },

    totalUser:{
        width:'100%',
        fontSize: '20px',
        // [theme.breakpoints.down('xs')]:{
        //     height: 'auto'
        // }

    },
    soldChart:{
        width: '100%'
    },
    downloadButton:{
        width:'100%',
        [theme.breakpoints.down('xs')]:{
            fontSize:'13px'
        },
        [theme.breakpoints.down('sm')]:{
            fontSize:'9px'
        }
    },
    graphTitle:{
        paddingTop: theme.spacing.unit *1,
        color:'#22201F',
        fontSize: 15,
        fontWeight:'500',
        textAlign:'center'
    },
    soldDeviceDropdown:{
        margin: theme.spacing.unit *2
    },
    contentInfo:{
        flexGrow:'2',
        maxWidth:'50%',
        padding:theme.spacing.unit *1
    },
})
