import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Button, IconButton,Typography } from '@material-ui/core';

// Material icons
import {
  Delete as DeleteIcon,
  Search as SearchIcon
} from '@material-ui/icons';

import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';

// Shared components
import {  SearchInput } from 'components';

// Component styles
import styles from './styles';


const DialogContent = withStyles(theme => ({
  }))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  }))(MuiDialogActions);
  
class Toolbar extends Component {
  state = {
    searchInput:'',
    openModel:false
  }
  onInsert(){
   this.props.onAdd();
   
  }
  handleDeleteUsers(){
    this.props.onDelete(this);
  }
  handleFieldChange(value){
    this.setState({searchInput: value});
    this.props.onSearch(value,true);
  }
  
  onClear(){
    this.setState({searchInput: ''});
    this.props.onSearch('',false);
  }

  actionModel(valid){
    if(valid){
      this.handleDeleteUsers();
    }
    this.setState({ openModel: false });
  }
  openPopupbox() {
    this.setState({ openModel: true });
  }
  
  render() {
    const { classes, className, selectedUsers,deleteButton,addButton } = this.props;
    const { searchInput } =this.state;
    const rootClassName = classNames(classes.root, className);
    return (
      <div className={rootClassName}>
        <div className={classes.row}>
          <span className={classes.spacer} />
          {(selectedUsers.length > 0 && deleteButton)  && (
            <IconButton
              className={classes.deleteButton}
              
              onClick={()=>this.openPopupbox()}
            >
              <DeleteIcon />
            </IconButton>
          )}
          {addButton ? <Button
            color="primary"
            size="small"
            variant="outlined"
            onClick={()=>this.onInsert()}
          >
            Add
          </Button>:null}
          
        
          <Dialog open={this.state.openModel}  maxWidth = {'xs'} >
            <DialogContent dividers>
                <Typography>
                    <h3>Are you sure to delete the user's</h3>
                </Typography>
                <Button onClick={()=>this.actionModel(true)} color="primary">
                  confirm
              </Button>
              <Button onClick={()=>this.actionModel(false)} color="primary">
                  Cancel
              </Button>
            </DialogContent>
            
          </Dialog>
        </div>
        <div className={classes.row}>
          <SearchInput
            className={classes.searchInput}
            placeholder="Search"
            name="searchInput"
            onChange={event =>
              this.handleFieldChange(event.target.value)
            }
            type="text"
            value={searchInput}
            onClear={()=>this.onClear()}
          />
          <span className={classes.spacer} />
        </div>
      </div>
    );
  }
}

Toolbar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  selectedUsers: PropTypes.array,
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
  onSearch:PropTypes.func,
  deleteButton:PropTypes.bool,
  addButton:PropTypes.bool,
};

Toolbar.defaultProps = {
  selectedUsers: [],
  onAdd: () => {},
  onDelete:()=>{},
  onSearch:()=>{}
};

export default withStyles(styles)(Toolbar);
