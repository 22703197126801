import React, { Component } from 'react';
// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';
import _ from 'underscore';
// Material helpers
import { withStyles, Typography  } from '@material-ui/core';
// Material components
import { Button, TextField } from '@material-ui/core';
// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components';
import { userProfile,updateProfile } from 'services/user';
import {Toast} from 'services/common'
import { ToastContainer } from 'react-toastify';
import validate from 'validate.js';
import validators from 'common/validators';
// Component styles
import styles from './styles';
validate.validators.checked = validators.checked;
const schema = {
  fullName: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 25
    }
  },
  email: {
    presence: { allowEmpty: false, message: 'is required or ' },
    email: true,
    length: {
      maximum: 64
    }
  },
  mobileNumber:{
    presence: { allowEmpty: false, message:'is required or'},
    length:{
      maximum:15,
      minimum:10
    },
    format:{
      pattern:/^[0-9A-Fa-f]+$/,
      message:"should be in hexa format"
    }
  }
};
class Account extends Component {
  state = {
    values: {
      fullName: '',
      email: '',
      mobileNumber:'',
      company: '',
      address:''
    },
    touched: {
      fullName: false,
      email: false
    },
    errors: {
      fullName: null,
      email: null
    },
    isValid: false,
    isLoading: false,
    submitError: null
  };
  validateForm = _.debounce(() => {
    const { values } = this.state;
    const newState = { ...this.state };
    const errors = validate(values, schema);
    newState.errors = errors || {};
    newState.isValid = errors ? false : true;
    this.setState(newState);
  }, 300);
  componentWillMount() {
    this.getProfile();
  }
  async getProfile() {
    try {
      this.setState({ isLoading: true });
      let getPro = await userProfile();
      if (getPro.success) {
        let profileDeatil=getPro.info;
        let info ={
          fullName:profileDeatil.name,
          email:profileDeatil.email,
          mobileNumber:profileDeatil.mobileNumber,
          company:profileDeatil.company,
          address:profileDeatil.address
        }
        this.setState({
           values:info,
            role:profileDeatil.role
          
        });
      }
    } catch (error) {
      if (this.signal) {
        this.setState({
          isLoading: false,
          error
        });
      }
    }
  }
  async updateProfileInfo(profileDeatil) {
    try {
      this.setState({ isLoading: true });
      let info ={
        name:this.state.values.fullName,
        email:this.state.values.email,
        mobileNumber:this.state.values.mobileNumber,
        company:this.state.values.company,
        address:this.state.values.address
      };
      let userInfo = await updateProfile(info);
      if(userInfo.success === true){
        Toast.successToast("Profile update successfully");
      }else{
        Toast.errorToast(userInfo.error);
        this.setState({
          isLoading: false,
          serviceError: userInfo.error
        });
      }
    } catch (error) {
      Toast.errorToast("server error");
      if (this.signal) {
        this.setState({
          isLoading: false,
          error
        });
      }
    }
  }
  
  
  handleFieldChange = (field, value) => {
    const newState = { ...this.state };
    newState.submitError = null;
    newState.touched[field] = true;
    newState.values[field] = value;
    // this.setState({[field]:value});
    this.setState(newState, this.validateForm);
  };
  _renderInput(label,text,value){
    const { classes} = this.props;
    const { touched, errors} =this.state;
    const showfullNameError =
    touched.fullName && errors.fullName ? errors.fullName[0] : false;
    const showemailError =
      touched.email && errors.email ? errors.email[0] : false;
    const showmobileNumberError =
      touched.mobileNumber && errors.mobileNumber ? errors.mobileNumber[0] : false;
    return(
      <>
        <TextField
          className={classes.textField}
          label={label}
          margin="dense"
          // required
          value={value}
          variant="outlined"
          name={text}
          type="text"
          onChange={event =>
            this.handleFieldChange(text, event.target.value)
          }
        />
        {['show']+text+['Error'] && (
          <Typography
            className={classes.fieldError}
            variant="body2"
          >            
            {errors[text]}
          </Typography>
        )}
      </>
    )
  }
  render() {
    const { classes, className, ...rest } = this.props;
    // const { fullName, email, mobileNumber, company, address} =this.state;
    const { values, touched, errors, isValid, submitError, isLoading} =this.state;
    const rootClassName = classNames(classes.root, className);
    return (
      <Portlet
        {...rest}
        className={rootClassName}
      >
        <PortletHeader>
          <PortletLabel
            subtitle="The information can be edited"
            title="Profile"
          />
        </PortletHeader>
        <PortletContent noPadding>
          <form
            autoComplete="off"
            // noValidate
          >
            <div className={classes.field}>
              {this._renderInput('Full name*','fullName',values.fullName)}
              {this._renderInput('Email Address*','email',values.email)}
              {this._renderInput('Phone Number*','mobileNumber',values.mobileNumber)}
              {this._renderInput('Company name','company',values.company)}
              {this._renderInput('Address','address',values.address)}
            </div>
          </form>
        </PortletContent>
        <PortletFooter className={classes.portletFooter}>
          <Button
            color="primary"
            variant="contained"
            disabled={!isValid}
            onClick={()=>this.updateProfileInfo()}
          >
            Save details
          </Button>
        </PortletFooter>
        <ToastContainer />
      </Portlet>
    );
  }
}
Account.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  profileDeatil: PropTypes.object.isRequired
};
Account.defaultProps = {
  profileDeatil: {}
};
export default withStyles(styles)(Account);
