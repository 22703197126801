import React, {Component, Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import {AuthToken} from 'services/common';

// Externals
import classNames from 'classnames';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';

// Material helpers
import {withStyles} from '@material-ui/core';

// Material components
import {
    Badge,
    IconButton,
    Popover,
    Toolbar,
    Typography,
    Tooltip
} from '@material-ui/core';

// Material icons
import {
    Menu as MenuIcon,
    Close as CloseIcon,
    NotificationsOutlined as NotificationsIcon,
    Input as InputIcon
} from '@material-ui/icons';


// Component styles
import styles from './styles';
import moment from 'moment';

class Topbar extends Component {

    signal = true;

    state = {
        isLoading: false,
        notifications: [],
        notificationsLimit: 4,
        notificationsCount: 0,
        notificationsEl: null,
    };

    async componentDidMount() {
        this.signal = true;
        window.onclick = function () {
            let currentMin = moment().add(10, 'minutes').utc().valueOf();
            sessionStorage.setItem('userClickedMin', currentMin);
        }.bind(this);

        window.addEventListener("scroll", (event) => {
            let currentMin = moment().add(10, 'minutes').utc().valueOf();
            sessionStorage.setItem('userClickedMin', currentMin);
        });

        if (sessionStorage.length > 0) {
            //set interval for every min
            window.setInterval(this.autoSignOut, 60000);
        }
    }

    autoSignOut = () => {
        this.setState({isLoading: true});
        let currentMin = moment().utc().valueOf()
        let userClickedMin = sessionStorage.userClickedMin;
        if (userClickedMin <= currentMin) {
            this.handleSignOut();
        }
    }
    // componentWillUnmount() {
    //   this.signal = false;
    //   let currentMin = moment().add(2,'minutes').utc().valueOf();
    //   sessionStorage.setItem('userClickedMin',currentMin);
    // }

    handleSignOut = () => {
        this.setState({isLoading: true});
        const {history} = this.props;
        setTimeout(() => {
            localStorage.clear();
            sessionStorage.clear();
            localStorage.setItem('isAuthenticated', false);
            history.push('/sign-in');
        }, 200);
    };

    handleShowNotifications = event => {
        this.setState({
            notificationsEl: event.currentTarget
        });
    };

    handleCloseNotifications = () => {
        this.setState({
            notificationsEl: null
        });
    };

    render() {
        const {
            classes,
            className,
            title,
            isSidebarOpen,
            onToggleSidebar
        } = this.props;
        const {notifications, notificationsCount, notificationsEl} = this.state;

        const rootClassName = classNames(classes.root, className);
        const showNotifications = Boolean(notificationsEl);

        return (
            <Fragment>
                <div className={rootClassName}>
                    <Toolbar className={classes.toolbar}>
                        <IconButton
                            className={classes.menuButton}
                            onClick={onToggleSidebar}
                            variant="text"
                        >
                            {isSidebarOpen ? <CloseIcon/> : <MenuIcon/>}
                        </IconButton>
                        <Typography
                            className={classes.title}
                            variant="h4"
                        >
                            {title}
                        </Typography>
                        <Tooltip title="Notifications">
                            <IconButton
                                className={classes.notificationsButton}
                                onClick={this.handleShowNotifications}
                            >
                                <Badge
                                    badgeContent={notificationsCount}
                                    color="primary"
                                    variant="dot"
                                >
                                    <NotificationsIcon/>
                                </Badge>
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="Sign Out">
                            <IconButton
                                className={classes.signOutButton}
                                onClick={this.handleSignOut}
                            >
                                <InputIcon/>
                            </IconButton>
                        </Tooltip>

                    </Toolbar>
                </div>
                <Popover
                    anchorEl={notificationsEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    onClose={this.handleCloseNotifications}
                    open={showNotifications}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                >
                </Popover>
            </Fragment>
        );
    }
}

Topbar.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    isSidebarOpen: PropTypes.bool,
    onToggleSidebar: PropTypes.func,
    title: PropTypes.string
};

Topbar.defaultProps = {
    onToggleSidebar: () => {
    }
};

export default compose(
    withRouter,
    withStyles(styles)
)(Topbar);
