import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

// Externals
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import validate from 'validate.js';
import _ from 'underscore';
import { newPassword } from 'services/user';
import { Toast } from 'services/common'
import { ToastContainer } from 'react-toastify';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';

// Shared utilities
import validators from 'common/validators';

// Component styles
import styles from './styles';

// Form validation schema

// Shared components
import { CardImage } from 'components';

validate.validators.checked = validators.checked;
const schema = {
  newPassword: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      minimum: 5,
      maximum: 15
    }
  },
  confirmPassword: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      minimum: 5,
      maximum: 15
    }
  }
}
class NewPassword extends Component {
  state = {
    values: {
      newPassword: '',
      confirmPassword: ''
    },
    touched: {
      newPassword: false,
      confirmPassword: false
    },
    errors: {
      newPassword: null,
      confirmPassword: null
    },
    isValid: false,
    isLoading: false,
    submitError: null
  };
  componentDidMount() {
    this.setState({
      userId: this.props.match.params.userId
    });

  }
  handleBack = () => {
    const { history } = this.props;

    history.goBack();
  };

  validateForm = _.debounce(() => {
    const { values } = this.state;

    const newState = { ...this.state };
    const errors = validate(values, schema);

    newState.errors = errors || {};
    newState.isValid = errors ? false : true;

    this.setState(newState);
  }, 300);

  handleFieldChange = (field, value) => {
    const newState = { ...this.state };

    newState.submitError = null;
    newState.touched[field] = true;
    newState.values[field] = value;

    this.setState(newState, this.validateForm);
  };

  handleSubmit = async () => {
    try {
      const { history } = this.props;
      const { values } = this.state;
      if (values.confirmPassword !== values.newPassword) {
        this.setState({ isLoading: false });
        Toast.errorToast("confirm password not match")
        return;
      }
      this.setState({ isLoading: true });
      let forgot = await newPassword({
        userId: values.userId ? values.userId : this.props.match.params.userId,
        password: values.newPassword
      });
      if (forgot.success) {
        Toast.successToast("New password set successfully ,please try to login new password");
        this.setState({
          isLoading: false
        });
        history.push('/sign-in');
      } else {
        Toast.errorToast(forgot.error);
        this.setState({
          isLoading: false
        });
      }

      //history.push('/sign-in');
    } catch (error) {
      this.setState({
        isLoading: false,
        serviceError: error
      });
    }
  };

  render() {
    const { classes } = this.props;
    const {
      values,
      touched,
      errors,
      isValid,
      submitError,
      isLoading
    } = this.state;

    const showNewPasswordError =
      touched.newPassword && errors.newPassword ? errors.newPassword[0] : false;
    const showConfirmPasswordError =
      touched.confirmPassword && errors.confirmPassword ? errors.confirmPassword[0] : false;

    return (
      <div className={classes.root}>
        <Grid
          className={classes.grid}
          container
        >
          <Grid
            className={classes.quoteWrapper}
            item
            lg={5}
          >
            <CardImage />
          </Grid>
          <Grid
            className={classes.content}
            item
            lg={7}
            xs={12}
          >
            <div className={classes.content}>
              <div className={classes.contentBody}>
                <form className={classes.form}>
                  <Typography
                    className={classes.title}
                    variant="h2"
                  >
                    New Password Set
                  </Typography>

                  <div className={classes.fields}>

                    <TextField
                      className={classes.textField}
                      label="New Password"
                      name="newPassword"
                      onChange={event =>
                        this.handleFieldChange('newPassword', event.target.value)
                      }
                      type="password"
                      value={values.newPassword}
                      variant="outlined"
                    />
                    {showNewPasswordError && (
                      <Typography
                        className={classes.fieldError}
                        variant="body2"
                      >
                        {errors.newPassword[0]}
                      </Typography>
                    )}

                    <TextField
                      className={classes.textField}
                      label="Confirm Password"
                      name="confirmPassword"
                      onChange={event =>
                        this.handleFieldChange('confirmPassword', event.target.value)
                      }
                      type="password"
                      value={values.confirmPassword}
                      variant="outlined"
                    />
                    {showConfirmPasswordError && (
                      <Typography
                        className={classes.fieldError}
                        variant="body2"
                      >
                        {errors.confirmPassword[0]}
                      </Typography>
                    )}

                  </div>
                  {submitError && (
                    <Typography
                      className={classes.submitError}
                      variant="body2"
                    >
                      {submitError}
                    </Typography>
                  )}
                  {isLoading ? (
                    <CircularProgress className={classes.progress} />
                  ) : (
                      <Button
                        className={classes.signUpButton}
                        color="primary"
                        disabled={!isValid}
                        onClick={this.handleSubmit}
                        size="large"
                        variant="contained"
                      >
                        Submit
                      </Button>
                    )}
                  <Typography
                    className={classes.signIn}
                    variant="body1"
                  >
                    Back to ?{' '}
                    <Link
                      className={classes.signInUrl}
                      to="/sign-in"
                    >
                      Sign In
                    </Link>
                  </Typography>
                </form>
              </div>
            </div>
          </Grid>
        </Grid>
        <ToastContainer />
      </div>
    );
  }
}

NewPassword.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  withStyles(styles)
)(NewPassword);
