import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Externals
import PropTypes from 'prop-types';

// Material helpers
import { withStyles, TableHead, Table, TableRow, TableCell, TableBody } from '@material-ui/core';

// Material components
import {
    IconButton,
    CircularProgress,
    Grid,
    Button,
    TextField,
    Typography
} from '@material-ui/core';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';
import { ToastContainer } from 'react-toastify';
// Shared services
import { addDevice } from 'services/product';
import { Toast } from 'services/common'
// Component styles
import styles from './styles';
import validate from 'validate.js';
import validators from 'common/validators';
import _ from 'underscore';
import { getParentSellers, getLastOwner } from 'services/user';
import Dropdown from 'components/Dropdown';
import SellerDropdown from 'components/SellerDropdown';

import PerfectScrollbar from 'react-perfect-scrollbar';
import CONFIG from '../../services/config'

const schema = {
    udiNumber: {
        presence: { allowEmpty: false, message: 'is required' },
        length: { minimum: 8, maximum: 50 }
    },
    Lot_Number: {
        presence: { allowEmpty: true }
    },
    Company_Name: {
        presence: { allowEmpty: true }
    },
    k_number: {
        presence: { allowEmpty: true }
    },
    FDA_Registration_Number: {
        presence: { allowEmpty: true }
    },
    Product_Code: {
        presence: { allowEmpty: true }
    },
    Device_Name: {
        presence: { allowEmpty: true }
    },
    Device_Count: {
        presence: { allowEmpty: true }
    },
    Brand_Name: {
        presence: { allowEmpty: true }
    },
    Regulation_Number: {
        presence: { allowEmpty: true }
    },
    Version_Color_Power: {
        presence: { allowEmpty: true }
    },
    Device_Description: {
        presence: { allowEmpty: true }
    }
}

class DeviceAdd extends Component {
    state = {
        file: {},
        values: {
            udiNumber: '',
            Lot_Number: '',
            Company_Name: '',
            k_number: '',
            FDA_Registration_Number: '',
            Product_Code: '',
            Device_Name: '',
            // Sterilization: '',
            Device_Count: '',
            Brand_Name: '',
            Regulation_Number: '',
            Version_Color_Power: '',
            Device_Description: ''
        },
        touched: {
            udiNumber: false,
            selectedSeller: false
        },
        errors: {
            udiNumber: null,
        },
        isValid: false,
        isLoading: false,
        submitError: null,
        deviceLog: [],
        parentSellerList: [],
        device_exist: false,
        is_owner: false,
        has_zero: false
    };

    validateForm = _.debounce(() => {
        const { values } = this.state;
        const newState = { ...this.state };
        const errors = validate(values, schema);
        newState.errors = errors || {};
        newState.isValid = errors ? false : true;
        const authType = localStorage.getItem('authType');
        // if (authType != 'MANUFACTURER' && newState.isValid === true) {
        //     if (this.state.touched.selectedSeller === false) {
        //         this.getParentSellerList();
        //     }
        //     const selectedSeller = newState.values.selectedSeller;
        //     if (typeof selectedSeller === 'undefined' || selectedSeller === '') {
        //         newState.isValid = false;
        //     }
        // }
        this.setState(newState);

    }, 300);

    async handleFieldChange(field, value) {

        // document.getElementById("addDevice").reset();
        const newState = { ...this.state };
        if (field === 'udiNumber') {
            newState.touched['selectedSeller'] = false;
        }
        newState.submitError = null;
        newState.touched[field] = true;
        newState.values[field] = value;
        newState.deviceLog = [];
        this.setState(newState, this.validateForm);

        // check this UDI exists in the chain
        await this.existDevice();
        // check this UDI number has zero number like (01)0000000000(17)210930(10)02011180
        await this.check_UDI_contains_zero(value);
    };
    async handleSellerDorpdownChange(field, value) {

        document.getElementById("addDevice").reset();
        const newState = { ...this.state };
        newState.submitError = null;
        newState.touched[field] = true;
        newState.values[field] = value;
        newState.deviceLog = [];
        this.setState(newState, this.validateForm);
    };

    async handleZeroFieldChange(field, value) {
        // document.getElementById("addDevice").reset();
        const newState = { ...this.state };
        if (field === 'udiNumber') {
            newState.touched['selectedSeller'] = false;
        }
        newState.submitError = null;
        newState.touched[field] = true;
        newState.values[field] = value;
        newState.deviceLog = [];
        this.setState(newState, this.validateForm);
    };

    async check_UDI_contains_zero(udi) {
        if (/\)(.*)\(/.test(udi)) {
            // console.log(udi);
            var result = udi.match(/\)(.*)\(/);
            // console.log(result);
            if (result.length == 2) {
                var string_to_check = result[1].split('(');
                // console.log(string_to_check[0])
                var has_zero = /^0*$/.test(string_to_check[0])
                // console.log(has_zero);
                await this.setState({
                    has_zero: has_zero
                });
            }
        }
    }
    async existDevice() {
        try {
            this.setState({ isLoading: true, device_exist: false, is_owner: false });
            let last_owner = await getLastOwner({ uid: this.state.values.udiNumber });
            if (last_owner.success === true && last_owner.list.length > 0) {
                await this.setState({
                    // parentSellerList: last_owner.list,
                    parentSellerList: last_owner.lastpurchaseId,
                    isLoading: false,
                    device_exist: true
                });
                last_owner.list.map(
                    item => {
                        if (item == CONFIG.COMPANYID) {
                            this.setState({
                                is_owner: true
                            });
                        }
                    }
                )
            } else {
                Toast.errorToast(last_owner.error);
                await this.setState({
                    isLoading: false,
                    serviceError: last_owner.error
                });
            }
        } catch (error) {
        }

        //Set error message for device exist or not
        // const authType = localStorage.getItem('authType');
        // if (authType != 'MANUFACTURER') {
        //     const newState = {...this.state};
        //     if (!(this.state.parentSellerList.length)) {
        //         newState.errors.udiNumber = ["Device Not Exist"];
        //         this.setState({newState});
        //     }
        // }
    }
    async onChangeFile(event) {
        event.preventDefault();
        this.setState({ deviceLog: [] })
        this.state.values.udiNumber = '';
        let file = event.target.files[0];
        let isValid = false;
        const type = ["text/csv", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"];
        let uploadFile = "Allow only .xls, .xlsx, .csv file";
        if (typeof file !== 'undefined') {
            if (type.indexOf(file.type) !== -1) {
                isValid = true;
                uploadFile = '';
                this.setState({ file: file })
            }
        } else {
            uploadFile = '';
        }
        const newState = { ...this.state };
        newState.errors['uploadFile'] = uploadFile;
        await this.setState({
            isValid: isValid,
            newState
        });
    }

    async getParentSellerList() {
        try {
            this.setState({ isLoading: true });
            let parentSellers = await getParentSellers({ uid: this.state.values.udiNumber });
            if (parentSellers.success === true) {
                this.setState({
                    parentSellerList: parentSellers.list,
                    isLoading: false,
                });
            } else {
                Toast.errorToast(parentSellers.error);
                this.setState({
                    isLoading: false,
                    serviceError: parentSellers.error
                });
            }
        } catch (error) {
        }

        //Set error message for device exist or not
        const authType = localStorage.getItem('authType');
        if (authType != 'MANUFACTURER') {
            const newState = { ...this.state };
            if (!(this.state.parentSellerList.length)) {
                newState.errors.udiNumber = ["Device Not Exist"];
                this.setState({ newState });
            }
        }
    }

    async handleAddDevice() {
        try {
            const { history } = this.props;
            const { values, device_exist, has_zero } = this.state;
            this.setState({ isLoading: true });
            let id = values.udiNumber;
            let formdata;
            const authType = localStorage.getItem('authType');
            // let sellerId = values.selectedSeller;
            // if ((authType === 'MANUFACTURER')) {
            //     sellerId = localStorage.getItem('authId');
            // }
            const sellerId = device_exist ? values.selectedSeller : CONFIG.COMPANYID;
            if (values.udiNumber && device_exist) {

                formdata = {
                    has_zero: has_zero,
                    uid: [values.udiNumber],
                    sellerId: sellerId,
                    companyid: CONFIG.COMPANYID
                }
            }
            else if (values.udiNumber && !device_exist) {
                if (has_zero) {
                    formdata = {
                        has_zero: has_zero,
                        uid: [values.udiNumber],
                        // BEGIN: fields for has_zero
                        Lot_Number: values.Lot_Number,
                        Company_Name: values.Company_Name,
                        k_number: values.k_number,
                        FDA_Registration_Number: values.FDA_Registration_Number,
                        Product_Code: values.Product_Code,
                        Device_Name: values.Device_Name,
                        // Sterilization: has_zero_values.Sterilization,
                        Device_Count: values.Device_Count,
                        Brand_Name: values.Brand_Name,
                        Regulation_Number: values.Regulation_Number,
                        Version_Color_Power: values.Version_Color_Power,
                        Device_Description: values.Device_Description,
                        // END:
                        sellerId: sellerId,
                        companyid: CONFIG.COMPANYID
                    }
                } else {
                    formdata = {
                        has_zero: has_zero,
                        uid: [values.udiNumber],
                        sellerId: sellerId,
                        companyid: CONFIG.COMPANYID
                    }
                }

            }
            // else {
            //     formdata = new FormData();
            //     formdata.append('file', this.state.file);
            //     formdata.append('companyid', CONFIG.COMPANYID);

            // }

            let addDev = await addDevice(formdata);
            if (addDev.success) {
                this.setState({
                    deviceLog: addDev.uilog.deviceLogs,
                    isLoading: false
                })
                {
                    this._renderDevicelog()
                }
                // history.push('/device');
            } else {
                //Toast.errorToast(addDev.error);
                this.setState({
                    isLoading: false,
                });
            }
        } catch (error) {
            Toast.errorToast(error.error !== undefined ? error.error : "server error");
            this.setState({
                isLoading: false,
                serviceError: error
            });
        }
    }

    _renderDevicelog() {
        const { classes } = this.props;
        if (this.state.deviceLog != '') {
            return (
                <PerfectScrollbar>
                    <Table className={classes.tableResponse}>
                        <TableHead>
                            <strong className={classes.responseFontSize}>Added device Log</strong>
                            <TableRow>
                                <TableCell>
                                    ID
                                </TableCell>
                                <TableCell>
                                    Success
                                </TableCell>
                                <TableCell>
                                    Message
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.deviceLog.map((responseLog, index) => (
                                <TableRow>
                                    <TableCell>
                                        {responseLog.id}
                                    </TableCell>
                                    <TableCell>
                                        {responseLog.success ? 'success' : 'fail'}
                                    </TableCell>
                                    <TableCell>
                                        {responseLog.message}
                                    </TableCell>
                                </TableRow>))}
                        </TableBody>
                    </Table>
                </PerfectScrollbar>
            );
        }
    }

    render() {
        const { classes } = this.props;
        const { values, has_zero_values, has_zero, touched, errors, isValid, submitError, isLoading, parentSellerList, device_exist, is_owner } = this.state;
        const authType = localStorage.getItem('authType');
        const showUDINumberError =
            touched.udiNumber && errors.udiNumber ? errors.udiNumber[0] : false;

        return (
            <DashboardLayout title="AddDevice">
                <div className={classes.root}>
                    <Grid container justify="center" spacing={4}>
                        <Grid item lg={12} xs={12}>
                            <Grid className={classes.content} item lg={12} xs={12}>
                                <div className={classes.content}>
                                    <div className={classes.contentBody}>
                                        <form className={classes.form} id="addDevice" encType="multipart/form-data">
                                            <div className={classes.fields}>
                                                <p className={classes.UDIlabel}>
                                                    * If UDI is available in GUDID, input format should be like (xx)xxxxxxxxxxxxxx(xx)xxxxxx(xx)xxxxxxxxxxx.
                                                </p>
                                                <p className={classes.UDIlabel}>
                                                    * If not available in GUDID, (xx)00000000000000(xx)xxxxxx(xx)xxxxxxxxxxx
                                                </p>
                                                <TextField
                                                    className={classes.textField}
                                                    label="UDI Number"
                                                    name="udiNumber"
                                                    // onBlur={event =>
                                                    //     this.handleFieldChange('udiNumber', event.target.value)
                                                    // }
                                                    onChange={event =>
                                                        this.handleFieldChange('udiNumber', event.target.value)
                                                    }
                                                    value={values.udiNumber}
                                                    variant="outlined"
                                                />
                                                {showUDINumberError && (
                                                    <Typography
                                                        className={classes.fieldError}
                                                        variant="body2"
                                                    >
                                                        {errors.udiNumber[0]}
                                                    </Typography>
                                                )}
                                                {parentSellerList.length && device_exist && is_owner ?
                                                    <Typography
                                                        className={classes.fieldError}
                                                        variant="body2"
                                                    >
                                                        You are owner of this device.
                                                    </Typography>
                                                    : ''
                                                }
                                                {/* BEGIN: show zero form fields */}
                                                {has_zero && !device_exist ?
                                                    (<div>
                                                        <br></br>

                                                        <p>Please input the following manually</p>
                                                        <TextField
                                                            className={classes.textField}
                                                            label="Lot Number (GUDID)"
                                                            name="Lot_Number"
                                                            onChange={event =>
                                                                this.handleZeroFieldChange('Lot_Number', event.target.value)
                                                            }
                                                            value={values.Lot_Number}
                                                            variant="standard"
                                                            type="text" />
                                                        <TextField
                                                            className={classes.textField}
                                                            label="Company Name"
                                                            name="Company_Name"
                                                            onChange={event =>
                                                                this.handleZeroFieldChange('Company_Name', event.target.value)
                                                            }
                                                            value={values.Company_Name}
                                                            variant="standard" />
                                                        <TextField
                                                            className={classes.textField}
                                                            label="510k Number"
                                                            name="k_number"
                                                            onChange={event =>
                                                                this.handleZeroFieldChange('k_number', event.target.value)
                                                            }
                                                            value={values.k_number}
                                                            variant="standard" />
                                                        <TextField
                                                            className={classes.textField}
                                                            label="FDA Registration Number"
                                                            name="FDA_Registration_Number"
                                                            onChange={event =>
                                                                this.handleZeroFieldChange('FDA_Registration_Number', event.target.value)
                                                            }
                                                            value={values.FDA_Registration_Number}
                                                            variant="standard" />
                                                        <TextField
                                                            className={classes.textField}
                                                            label="Product Code"
                                                            name="Product_Code"
                                                            onChange={event =>
                                                                this.handleZeroFieldChange('Product_Code', event.target.value)
                                                            }
                                                            value={values.Product_Code}
                                                            variant="standard" />
                                                        <TextField
                                                            className={classes.textField}
                                                            label="Device Name"
                                                            name="Device_Name"
                                                            onChange={event =>
                                                                this.handleZeroFieldChange('Device_Name', event.target.value)
                                                            }
                                                            value={values.Device_Name}
                                                            variant="standard" />
                                                        {/* <TextField
                                                            className={classes.textField}
                                                            label="Sterilization"
                                                            name="Sterilization"
                                                            value={has_zero_values.Sterilization}
                                                            variant="standard" /> */}
                                                        <TextField
                                                            className={classes.textField}
                                                            label="Device Count"
                                                            name="Device_Count"
                                                            onChange={event =>
                                                                this.handleZeroFieldChange('Device_Count', event.target.value)
                                                            }
                                                            value={values.Device_Count}
                                                            variant="standard" />
                                                        <TextField
                                                            className={classes.textField}
                                                            label="Brand Name"
                                                            name="Brand_Name"
                                                            onChange={event =>
                                                                this.handleZeroFieldChange('Brand_Name', event.target.value)
                                                            }
                                                            value={values.Brand_Name}
                                                            variant="standard" />
                                                        <TextField
                                                            className={classes.textField}
                                                            label="Regulation Number"
                                                            name="Regulation_Number"
                                                            onChange={event =>
                                                                this.handleZeroFieldChange('Regulation_Number', event.target.value)
                                                            }
                                                            value={values.Regulation_Number}
                                                            variant="standard" />
                                                        <TextField
                                                            className={classes.textField}
                                                            label="Version/Color/Power"
                                                            name="Version_Color_Power"
                                                            onChange={event =>
                                                                this.handleZeroFieldChange('Version_Color_Power', event.target.value)
                                                            }
                                                            value={values.Version_Color_Power}
                                                            variant="standard" />
                                                        <TextField
                                                            className={classes.textField}
                                                            label="Device Description"
                                                            name="Device_Description"
                                                            onChange={event =>
                                                                this.handleZeroFieldChange('Device_Description', event.target.value)
                                                            }
                                                            value={values.Device_Description}
                                                            variant="standard" />
                                                    </div>)
                                                    : ''
                                                }
                                                {/* END: */}

                                                {parentSellerList.length && device_exist && !is_owner ?
                                                    <SellerDropdown
                                                        userList={parentSellerList}
                                                        label='Seller'
                                                        defaultValue="Please select seller"
                                                        onChange={event =>
                                                            this.handleSellerDorpdownChange("selectedSeller", event.target.value)
                                                        }
                                                    />
                                                    : ''}

                                                {/* <Typography variant="body1" align="center" className={classes.pt10}>
                                                    <img src="/images/icon/or.png" className={classes.ORimg}/>
                                                </Typography>
                                                <Button>
                                                    <input type="file" accept=".xls,.csv,.xlsx"
                                                           onChange={this.onChangeFile.bind(this)}/>
                                                </Button> */}
                                            </div>
                                            {/* <Typography>
                                                Note: only accept to xls,csv,xlsx extension file
                                            </Typography> */}
                                            {/* {submitError && (
                                                <Typography
                                                    className={classes.submitError}
                                                    variant="body2"
                                                >
                                                    {submitError}
                                                </Typography>
                                            )} */}

                                            {isLoading ? (
                                                <CircularProgress className={classes.progress} />
                                            ) : (
                                                    <Button
                                                        className={classes.addProductButton}
                                                        color="primary"
                                                        disabled={!isValid || is_owner}
                                                        onClick={this.handleAddDevice.bind(this)}
                                                        size="large"
                                                        variant="contained"
                                                    >
                                                        Add Device
                                                    </Button>
                                                )}
                                        </form>
                                        {this._renderDevicelog()}
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* <ToastContainer /> */}
                </div>
            </DashboardLayout>
        );
    }
}

DeviceAdd.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DeviceAdd);
