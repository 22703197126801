import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';

// Externals
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import validate from 'validate.js';
import _ from 'underscore';
import {Toast} from 'services/common'
import {ToastContainer} from 'react-toastify';

// Material helpers
import {withStyles} from '@material-ui/core';

// Material components
import {
    Grid, Checkbox,
    Button,
    CircularProgress,
    TextField,
    Typography
} from '@material-ui/core';


// Component styles
import styles from './styles';

// Form validation schema
import schema from './schema';

// api calling
import {signIn} from 'services/user';
// Shared components
import {CardImage} from 'components';

import Cookies from 'universal-cookie';

class SignIn extends Component {
    state = {
        values: {
            email: '',
            password: '',
            rememberMe: false
        },
        touched: {
            email: false,
            password: false
        },
        errors: {
            email: null,
            password: null
        },
        isValid: false,
        isLoading: false,
        submitError: null
    };

    componentDidMount() {
        const newState = {...this.state};
        const cookies = new Cookies();
        newState.values['email'] = cookies.get('email');
        newState.values['password'] = cookies.get('password');
        console.log(cookies.get('rememberMe'));
        if (cookies.get('rememberMe') === 'true') {
            newState.values['rememberMe'] = true;
            newState['isValid'] = true;
        }
        this.setState(newState);
    }

    handleBack = () => {
        const {history} = this.props;

        history.goBack();
    };

    validateForm = _.debounce(() => {
        const {values} = this.state;

        const newState = {...this.state};
        const errors = validate(values, schema);

        newState.errors = errors || {};
        newState.isValid = errors ? false : true;

        this.setState(newState);
    }, 300);

    handleFieldChange = (field, value) => {

        const newState = {...this.state};

        newState.submitError = null;
        newState.touched[field] = true;
        newState.values[field] = value;

        this.setState(newState, this.validateForm);
    };

    handleSignIn = async () => {
        try {
            const {history} = this.props;
            const {values} = this.state;
            this.setState({isLoading: true});
            let login = await signIn(values.email, values.password);
            const cookies = new Cookies();
            if (login.success === true) {
                const email = values.rememberMe ? values.email : '';
                const password = values.rememberMe ? values.password : '';
                cookies.set('email', email);
                cookies.set('password', password);
                cookies.set('rememberMe', values.rememberMe);

                localStorage.getItem('authType') === 'ADMIN' ?
                    history.push('/users')
                    // : history.push('/account');
                    : history.push('/device');

            } else {
                Toast.errorToast(login.message ? login.message : "Your login attempt was not successful. Please try again");
                this.setState({
                    isLoading: false,
                    serviceError: login.error
                });
            }
        } catch (error) {
            Toast.errorToast(error.error !== undefined ? error.error : "server error");
            this.setState({
                isLoading: false,
                serviceError: error
            });
        }
    };

    enterPressed(event) {
        var code = event.keyCode || event.which;
        if (code === 13) { //13 is the enter keycode
            //Do stuff in here
            this.handleSignIn();
        }
    }

    render() {
        const {classes, history} = this.props;
        const {
            values,
            touched,
            errors,
            isValid,
            submitError,
            isLoading
        } = this.state;
        const showEmailError = touched.email && errors.email;
        const showPasswordError = touched.password && errors.password;
        if (localStorage.getItem('authToken')) {
            history.push('/users');
        }
        return (
            <div className={classes.root}>
                <Grid
                    className={classes.grid}
                    container
                >
                    <Grid
                        className={classes.quoteWrapper}
                        item
                        lg={5}
                    >
                        <CardImage/>
                    </Grid>
                    <Grid
                        className={classes.content}
                        item
                        lg={7}
                        xs={12}
                    >
                        <div className={classes.content}>

                            <div className={classes.contentBody}>
                                <form className={classes.form}>
                                    <Typography
                                        className={classes.title}
                                        variant="h2"
                                    >
                                        Sign in
                                    </Typography>

                                    <div className={classes.fields}>
                                        <TextField
                                            className={classes.textField}
                                            label="Email address"
                                            name="email"
                                            onChange={event =>
                                                this.handleFieldChange('email', event.target.value)
                                            }
                                            type="text"
                                            value={values.email}
                                            variant="outlined"
                                        />
                                        {showEmailError && (
                                            <Typography
                                                className={classes.fieldError}
                                                variant="body2"
                                            >
                                                {errors.email[0]}
                                            </Typography>
                                        )}
                                        <TextField
                                            className={classes.textField}
                                            label="Password"
                                            name="password"
                                            onChange={event =>
                                                this.handleFieldChange('password', event.target.value)
                                            }
                                            type="password"
                                            value={values.password}
                                            variant="outlined"
                                            onKeyPress={this.enterPressed.bind(this)}
                                        />
                                        {showPasswordError && (
                                            <Typography
                                                className={classes.fieldError}
                                                variant="body2"
                                            >
                                                {errors.password[0]}
                                            </Typography>
                                        )}
                                        <Checkbox
                                            checked={values.rememberMe}
                                            className={classes.rememberMeCheckbox}
                                            color="primary"
                                            name="rememberMe"
                                            onChange={(event) =>
                                                this.handleFieldChange('rememberMe', !values.rememberMe)
                                            }
                                        />
                                        <Typography
                                            className={classes.rememberMeText}
                                            variant="body1"
                                        >
                                            Remember me
                                        </Typography>
                                    </div>

                                    {submitError && (
                                        <Typography
                                            className={classes.submitError}
                                            variant="body2"
                                        >
                                            {submitError}
                                        </Typography>
                                    )}
                                    {isLoading ? (
                                        <CircularProgress className={classes.progress}/>
                                    ) : (
                                        <Button
                                            className={classes.signInButton}
                                            color="primary"
                                            disabled={!isValid}
                                            onClick={this.handleSignIn}
                                            size="large"
                                            variant="contained"
                                        >
                                            Sign in now
                                        </Button>
                                    )}
                                    {/* <Typography
                                        className={classes.signUp}
                                        variant="body1"
                                    >
                                        Don't have an account?{' '}
                                        <Link
                                            className={classes.signUpUrl}
                                            to="/sign-up"
                                        >
                                            Sign up
                                        </Link>
                                    </Typography> */}
                                    <Typography
                                        className={classes.signUp}
                                        variant="body1"
                                    >

                                        <Link
                                            className={classes.signUpUrl}
                                            to="/forgot-password"
                                        >Forgot Password?
                                        </Link>
                                    </Typography>
                                </form>
                            </div>
                        </div>
                    </Grid>
                </Grid>
                <ToastContainer/>
            </div>
        );
    }
}

SignIn.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

export default compose(
    withRouter,
    withStyles(styles)
)(SignIn);
