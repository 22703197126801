import React from 'react';
import ReactDOM from 'react-dom';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';


// Service worker
import * as serviceWorker from './common/serviceWorker';

// App
import App from './App';

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
