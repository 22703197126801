import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function successToast(message){
  toast.success(message, {
    position: toast.POSITION.TOP_RIGHT
  });
  return;
}

function warningToast(message){
  toast.warn(message, {
    position: toast.POSITION.TOP_RIGHT
  });
  return;
}
function errorToast(message){
  toast.error(message, {
    position: toast.POSITION.TOP_RIGHT
  });
  return;
}

function loaderOverlay (){
  let  styles={
   overlay: (base) => ({
     ...base,
     background: 'transparent'
   }),
   spinner: (base) => ({
     ...base,
     width: '100px',
     '& svg circle': {
       stroke: 'rgba(33, 29, 29, 0.5)'
     }
   })
  };
 return styles;
} 
export default {
  successToast,
  warningToast,
  errorToast,
  loaderOverlay
};
