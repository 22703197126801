import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Button } from '@material-ui/core';

// Shared components
import { DisplayMode, SearchInput } from 'components';

// Component styles
import styles from './styles';

class DeviceToolbar extends Component {
  state = {
    searchInput:''
  }
  onInsert(){
   this.props.onAdd();
  }
  handleFieldChange(value){
    this.setState({searchInput: value});
    this.props.onSearch(value,true);
  }
  onClear(){
    this.setState({searchInput: ''});
    this.props.onSearch('',false);
  }
  render() {
    const { classes, className } = this.props;
    const { searchInput } =this.state;
    const rootClassName = classNames(classes.root, className);

    return (
      <div className={rootClassName}>
        <div className={classes.row}>
          <span className={classes.spacer} />
          <Button
            color="primary"
            size="small"
            variant="outlined"
            onClick={()=>this.onInsert()}
          >
            Add Device
          </Button>
        </div>
        <div className={classes.row}>
          <SearchInput
            className={classes.searchInput}
            name="searchInput"
            onChange={event =>
              this.handleFieldChange(event.target.value)
            }
            type="text"
            value={searchInput}
            onClear={()=>this.onClear()}
          />
          <span className={classes.spacer} />
        </div>
      </div>
    );
  }
}

DeviceToolbar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onAdd: PropTypes.func,
  onSearch:PropTypes.func
};
DeviceToolbar.defaultProps = {
  onAdd: () => {},
  onSearch:()=>{}
};

export default withStyles(styles)(DeviceToolbar);
