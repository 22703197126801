import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link, NavLink } from 'react-router-dom';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Avatar, Typography, Divider, InputLabel, Tooltip } from '@material-ui/core';

// Material icons
import {
    AccessTime as AccessTimeIcon,
    GetApp as GetAppIcon,
    ShoppingBasketOutlined as ShoppingBasketIcon,
    Work as WorkIcon,
    WorkOutline as WorkOutLineIcon

} from '@material-ui/icons';

// Shared helpers
import { getInitials } from 'helpers';
// Shared components
import { Paper } from 'components';

// Component styles
import styles from './styles';

class DeviceCard extends Component {
    render() {
        const { classes, className, device, all_purchase_sold } = this.props;
        const authType = localStorage.getItem('authType');
        const rootClassName = classNames(classes.root, className);
        const transactionId = this.props.device.transactionId;
        localStorage.setItem('searchDevice', false)
        let deviceFullInfo = {}

        deviceFullInfo = device;

        if (all_purchase_sold == 0) {
            deviceFullInfo = device;
        } else {
            deviceFullInfo = device.deviceDetails ? device.deviceDetails[0] : {};
        }
        // if (authType === 'MANUFACTURER' || authType === 'ADMIN') {
        //     deviceFullInfo = device;
        // } else {
        //     deviceFullInfo = device.deviceDetails ? device.deviceDetails[0] : {};
        // }


        return (
            <Paper className={rootClassName}>
                <div className={classes.imageWrapper}>
                    <Avatar
                        className={classes.avatar}
                        src={deviceFullInfo.imageUrl1}
                    >
                        {getInitials(deviceFullInfo.deviceName)}
                    </Avatar>
                </div>
                <div className={classes.details}>
                    <Typography
                        className={classes.title}
                        variant="h4"
                    >
                        {deviceFullInfo.deviceName}
                    </Typography>

                    <Typography
                        className={classes.description}
                        variant="body1"
                    >
                        {deviceFullInfo.description}
                    </Typography>
                    <Typography
                        className={classes.description}
                        variant="body1"
                    >
                        {deviceFullInfo.lotNumber}
                    </Typography>
                </div>
                <Divider />
                {all_purchase_sold == 0 || all_purchase_sold == 1 ?
                    <div className={classes.stats}>
                        <WorkIcon
                            className={`${classes.soldIcon} ${deviceFullInfo.ackSoldDevice === true ? classes.textSold : ''}`} />
                        <Typography
                            className={`${classes.workText} ${deviceFullInfo.ackSoldDevice === true ? classes.textSold : ''}`}
                            variant="h6"
                        >
                            Sold
                        </Typography>

                        <WorkOutLineIcon
                            className={`${classes.unsoldIcon} ${deviceFullInfo.ackSoldDevice === false ? classes.textUnSold : ''}`} />
                        <Typography
                            className={`${classes.workText} ${deviceFullInfo.ackSoldDevice === false ? classes.textUnSold : ''}`}
                            variant="h6"
                        >
                            UnSold
                        </Typography>
                    </div>
                    : null
                }
                {/* {authType != 'ENDUSER' && ackSoldDevice != true ?
                    <div className={classes.stats}>
                        <WorkIcon
                            className={`${classes.soldIcon} ${deviceFullInfo.ackSoldDevice === true ? classes.textSold : ''}`} />
                        <Typography
                            className={`${classes.workText} ${deviceFullInfo.ackSoldDevice === true ? classes.textSold : ''}`}
                            variant="h6"
                        >
                            Sold
                        </Typography>

                        <WorkOutLineIcon
                            className={`${classes.unsoldIcon} ${deviceFullInfo.ackSoldDevice === false ? classes.textUnSold : ''}`} />
                        <Typography
                            className={`${classes.workText} ${deviceFullInfo.ackSoldDevice === false ? classes.textUnSold : ''}`}
                            variant="h6"
                        >
                            UnSold
                        </Typography>
                    </div>
                    : null
                } */}
                <Divider />

                <div className={classes.action}>
                    <Typography
                        className={classes.actionText}
                        variant="body2"
                        style={{ justifyContent: 'center' }}
                    >
                        {/*<Link*/}
                        {/*className={classes.signUpUrl}*/}
                        {/*component={NavLink}*/}
                        {/*to={"/device-detail/" + transactionId}*/}
                        {/*>View History*/}
                        {/*</Link>*/}
                        <Tooltip title="View History of Device">
                            <NavLink to={"/device-detail/" + transactionId + "/" + all_purchase_sold} className={classes.signUpUrl}>
                                View History
                            </NavLink>
                        </Tooltip>

                    </Typography>
                </div>
            </Paper>
        );
    }
}

DeviceCard.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    device: PropTypes.object.isRequired
};

export default withStyles(styles)(DeviceCard);
