import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Externals
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import {
  IconButton,
  CircularProgress,
  Grid,
  Typography,
  TablePagination
} from '@material-ui/core';

// Material icons
import {
  ChevronRight as ChevronRightIcon,
  ChevronLeft as ChevronLeftIcon
} from '@material-ui/icons';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

// Shared services
import { serachDevices } from 'services/product';

// Custom components
import { Toolbar,TableView } from 'components';

// Component styles
import styles from './styles';

class SearchDevice extends Component {
  signal = true;

  state = {
    isLoading: false,
    currentPage:0,
    limit: 10,
    devices: [],
    devicesTotal: 0,
    error: null,
    searchText:''
  };

  async getDevices(currentPage,limit,searchText) {
    try {
      this.setState({ isLoading: true });
      searchText= searchText ==undefined ? '':searchText;
      const { devices, devicesTotal } = await serachDevices(currentPage,limit,searchText);
      if (this.signal) {
        this.setState({
          isLoading: false,
          devices,
          devicesTotal,
          limit
        });
      }
    } catch (error) {
      if (this.signal) {
        this.setState({
          isLoading: false,
          error
        });
      }
    }
  }

  componentWillMount() {
    this.signal = true;

    const { currentPage,limit,searchText } = this.state;

    //this.getDevices(currentPage,limit,searchText);
  }

  componentWillUnmount() {
    this.signal = false;
  }
 
  handleChangeRowsPerPage = event => {
    this.setState({ limit: event.target.value});
    this.getDevices(this.state.currentPage,event.target.value);
  };
  
  handlePageChange = (pageNo,rowPage) => {
    this.setState({ currentPage :pageNo,limit:rowPage });
    this.getUsers(pageNo,rowPage);
  };
  handleSearch = (searchText,clean) => {
    console.log("search text---------------",searchText);
    this.getDevices(0,this.state.limit,searchText);
    this.setState({currentPage:0,searchText:searchText});
  }
  handlePageView =(deviceId)=>{
    const  { history } = this.props;
    
    localStorage.setItem('searchDevice',true)
    history.push('/device-detail/'+deviceId);
  }
  renderDevices() {
   
    const { classes } = this.props;
    const { isLoading, devices ,devicesTotal,limit,currentPage,searchText, error } = this.state;

    const rowHeader =[
      { id: '_id', label: '_id' },
      { id: 'UDI', label: 'UDI' },
      { id: 'deviceName', label: 'Name' },
      { id: 'brandName', label: 'Brand' },
      { id: 'companyName', label: 'Company' },
      { id: 'createdAt', label: 'Created' }
    ]
    if (isLoading) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      );
    }

    if (error) {
      return <Typography variant="h6">{error}</Typography>;
    }

    if (devices.length === 0) {
      return <Typography variant="h6">There are no devices</Typography>;
    }

    return (
      <TableView
        onSelect={this.handleSelect}
        onShowRowHeader={rowHeader}
        users={devices}
        usersTotal={devicesTotal}
        page={currentPage}
        rowsPerPage={limit}
        onPageChange={this.handlePageChange}
        onSearch={searchText}
        onCheckBox={false}
        onViewButton={true}
        onView={this.handlePageView}
      />
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <DashboardLayout title="Devices">
        <div className={classes.root}>
          <Toolbar  
            onSearch={this.handleSearch}
            deleteButton={false} addButton={false}
          />
          <div className={classes.content}>{this.renderDevices()}</div>
          
          
        </div>
      </DashboardLayout>
    );
  }
}

SearchDevice.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SearchDevice);

