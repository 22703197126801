export default theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.border}`,
        backgroundColor: theme.palette.common.white,
        display: 'flex',
        alignItems: 'center',
        height: '64px',
        zIndex: theme.zIndex.appBar
    },
    toolbar: {
        minHeight: 'auto',
        width: '100%'
    },
    title: {
        marginLeft: theme.spacing.unit
    },
    menuButton: {
        marginLeft: '-4px',
        borderRadius: ' 9px;',
        background: '#2408f6;',
        padding: '0px;',
        width: '25px;',
        height: '25px;',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#2408f6'
        }
    },
    notificationsButton: {
        marginLeft: 'auto'
    },
    signOutButton: {
        marginLeft: theme.spacing.unit
    }
});
