export default theme => ({
  root: {
    padding: theme.spacing.unit * 3,
        [theme.breakpoints.down('xs')]: {
          padding: theme.spacing.unit * 2
        },
  },
  content: {
    marginTop: theme.spacing.unit * 2
  },
  progressWrapper: {
    paddingTop: theme.spacing.unit * 6,
    paddingBottom: theme.spacing.unit * 3,
    display: 'flex',
    justifyContent: 'center'
  }
});
