export default theme => ({
    distributorDropdown: {
        backgroundColor: '#ffffff',
        height: '40px',
        width: '100%',
        border: '0px',
        outline: '0px',
        borderBottom: '1px solid black'
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1.5),
        color: '#fff',
    },
    bgColor: {
        backgroundColor: '#0c067bb0'
    },
    color:{
        color:'#fff'
    },
    others: {
        color:'red !important' ,
        // "&:hover": {
        //     color: "black !important"
        //   }
    }
  });
  