export default theme => ({
  root: {
    padding: theme.spacing.unit * 3,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing.unit * 2
    },
  },
  content: {
    marginTop: theme.spacing.unit * 2
  },
  progressWrapper: {
    paddingTop: '48px',
    paddingBottom: '24px',
    display: 'flex',
    justifyContent: 'center'
  },
  pagination: {
    marginTop: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    // marginLeft: '10px'
  },
  form: {
    paddingLeft: '100px',
    paddingRight: '100px',
    paddingBottom: '10px',
    flexBasis: '700px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing.unit * 2,
      paddingRight: theme.spacing.unit * 2
    }
  },
  fields: {
    marginTop: theme.spacing.unit * 5
  },
  textField: {
    width: '100%',
    '& + & ': {
      marginTop: theme.spacing.unit * 2
    }
  },
  UDIlabel:{
    paddingBottom: '20px',
    color: '#66788A'
  },
  progress: {
    display: 'block',
    marginTop: theme.spacing.unit * 2,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  addProductButton: {
    marginTop: theme.spacing.unit * 2,
    width: '100%'
  },
  fieldError: {
    color: theme.palette.danger.main,
    marginBottom: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit
  },
  submitError: {
    color: theme.palette.danger.main,
    alignText: 'center',
    marginBottom: theme.spacing.unit,
    marginTop: theme.spacing.unit * 2
  },
  pt10: {
    paddingTop: 10
  },
  ORimg:{
    width:'5%'
  },
  tableResponse:{
    [theme.breakpoints.up('sm')]: {
      'margin': '20px 180px',
    },
    'width': '65%'
  },
  responseFontSize:{
    'fontSize':'17px'
  },
  userDropdown: {
    backgroundColor: '#ffffff',
    height: '45px',
    width: '100%',
    outline: '0px',
    border: '1px solid #66788A',
    borderRadius: '3px',
  },
  tablePaginationActions:{
    [theme.breakpoints.down('xs')]:{
    marginLeft: '0px'
    }
  },
  tablePaginationSelectRoot:{
    [theme.breakpoints.down('xs')]:{
    marginLeft:'0px',
    marginRight: '0px'
    }
  },
  displayTabs:{
    [theme.breakpoints.down('xs')]:{
      display:'block',
      textAlign:'center'
    }
  },

});
