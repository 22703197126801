import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { changePassword } from 'services/user';

// Material helpers
import { withStyles } from '@material-ui/core';
import _ from 'underscore';
import validate from 'validate.js';

// Material components
import { Button, TextField, Typography } from '@material-ui/core';
import { Toast } from 'services/common'
import { ToastContainer } from 'react-toastify';

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components';

// Component styles
import styles from './styles';
const schema = {
  oldPassword: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      minimum: 5,
      maximum: 10
    }
  },
  newPassword: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      minimum: 5,
      maximum: 10
    }
  },
  confirmPassword: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 15
    }
  }
};
class Password extends Component {
  state = {
    values: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: ''
    },
    touched: {
      oldPassword: false,
      newPassword: false,
      confirmPassword: false
    },
    errors: {
      oldPassword: null,
      newPassword: null,
      confirmPassword: null
    },
    isValid: false,
    isLoading: false,
    submitError: null
  };

  validateForm = _.debounce(() => {
    const { values } = this.state;

    const newState = { ...this.state };
    const errors = validate(values, schema);

    newState.errors = errors || {};
    newState.isValid = errors ? false : true;

    this.setState(newState);
  }, 300);

  handleFieldChange = (field, value) => {
    const newState = { ...this.state };
    newState.values[field] = value;
    newState.submitError = null;
    newState.touched[field] = true;

    this.setState(newState, this.validateForm);
  };

  handleUpdate = async () => {
    try {
      const { history } = this.props;
      const { values } = this.state;
      if (values.confirmPassword !== values.newPassword) {
        this.setState({ isLoading: false });
        Toast.errorToast("confirm password not match")
        return;
      }
      this.setState({ isLoading: true });
      let updatePwd = await changePassword({
        oldPassword: values.oldPassword, newPassword: values.newPassword
      });
      if (updatePwd.success === true) {
        Toast.successToast("successfully change password");
      } else {
        Toast.errorToast(updatePwd.error);
        this.setState({
          isLoading: false,
          serviceError: updatePwd.error
        });
      }
    } catch (error) {
      Toast.errorToast(error.error !== undefined ? error.error : "server error");
      this.setState({
        isLoading: false,
        serviceError: error
      });
    }
  };
  render() {
    const { classes, className, ...rest } = this.props;
    const {
      values,
      touched,
      errors,
      isValid,
      submitError,
      isLoading
    } = this.state;

    const rootClassName = classNames(classes.root, className);
    const showOldPasswordError = touched.oldPassword && errors.oldPassword;
    const showNewPasswordError = touched.newPassword && errors.newPassword;
    const showConfPasswordError = touched.confirmPassword && errors.confirmPassword;
    return (
      <Portlet
        {...rest}
        className={rootClassName}
      >
        <PortletHeader>
          <PortletLabel
            subtitle="Update password"
            title="Password"
          />
        </PortletHeader>
        <PortletContent>
          <form className={classes.form}>
            <TextField
              className={classes.textField}
              label="Old Password"
              name="oldPassword"
              onChange={event =>
                this.handleFieldChange('oldPassword', event.target.value)
              }
              type="password"
              value={values.oldPassword}
              variant="outlined"
            />
            {showOldPasswordError && (
              <Typography
                className={classes.fieldError}
                variant="body2"
              >
                {errors.oldPassword[0]}
              </Typography>
            )}
            <TextField
              className={classes.textField}
              label="New Password"
              name="newPassword"
              onChange={event =>
                this.handleFieldChange('newPassword', event.target.value)
              }
              type="password"
              value={values.newPassword}
              variant="outlined"
            />
            {showNewPasswordError && (
              <Typography
                className={classes.fieldError}
                variant="body2"
              >
                {errors.newPassword[0]}
              </Typography>
            )}
            <TextField
              className={classes.textField}
              label="Confirm password"
              name="confirmPassword"
              onChange={event =>
                this.handleFieldChange('confirmPassword', event.target.value)
              }
              type="password"
              value={values.confirmPassword}
              variant="outlined"
            />
            {showConfPasswordError && (
              <Typography
                className={classes.fieldError}
                variant="body2"
              >
                {errors.confirmPassword[0]}
              </Typography>
            )}
          </form>
        </PortletContent>
        <PortletFooter className={classes.portletFooter}>
          <Button
            color="primary"
            disabled={!isValid}
            variant="outlined"
            onClick={this.handleUpdate}
          >
            Submit
          </Button>
        </PortletFooter>
        <ToastContainer />
      </Portlet>
    );
  }
}

Password.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Password);
