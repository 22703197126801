const configration = {
    // API for user management
    // APIURL: "http://localhost:8000/",
    APIURL: "https://centralblockchain.hyper-clear.com:8000/",

    // API for block management, sub front-end has the defferent one with APIURL
    // CHAINAPIURL: "http://localhost:8000/",
    CHAINAPIURL: "https://centralblockchain.hyper-clear.com:8000/",
    COMPANYID: "Company1",
    COMPANYNAME: "Company1"
};
export default configration;
