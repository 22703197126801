import React from 'react';
import { withStyles } from '@material-ui/core';
import styles from './styles';
import TextField from "@material-ui/core/TextField";
import {Grid} from "@material-ui/core";
import PropTypes from 'prop-types';

const Datepicker = props => {
    const {classes,onChange} = props;

    return (
        <form noValidate>
            <Grid container spacing={3} className={classes.fromToDate}>
                    <TextField className={classes.textField}
                               label={props.label}
                               type="date"
                               format="yyyy/MM/dd"
                               onChange={onChange}
                               InputLabelProps={{
                                   shrink: true,
                               }}
                    />
            </Grid>
        </form>
    );
};
Datepicker.prototype = {
    onChange:PropTypes.func,
};
Datepicker.defaultProps = {
    onChange:()=>{},
};
export default withStyles(styles)(Datepicker);
