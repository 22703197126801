import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';


// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';


// Component styles
const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 4
  },
  item: {
    height: '100%'
  }
});

class Dashboard extends Component {
  
  render() {
    const { classes,history } = this.props;
    if(!localStorage.getItem('authToken')){
      history.push('/sign-in');
    }else{
      history.push('/account');
    }
    return (
      <DashboardLayout title="Dashboard">
        <div className={classes.root}>
         
        </div>
      </DashboardLayout>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Dashboard);
