import React from 'react';
import { OutlinedInput, InputLabel, FormHelperText, FormControl, Select, withStyles } from '@material-ui/core';
import styles from './styles';
import PropTypes from 'prop-types';
import UserList from 'views/UserList';

const Dropdown = props => {
    const { classes, onChange } = props;
    const [values, setValues] = React.useState({
    });

    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
    }, []);
    return (
        <form className={classes.root} autoComplete="off">

            <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel ref={inputLabel} htmlFor="outlined-age-simple">
                    {props.label}
                </InputLabel>
                <Select native
                    onChange={onChange}
                    input={<OutlinedInput labelWidth={labelWidth} name="manufacture" id="outlined-age-simple" />}
                    value={(props.selectedOption && props.selectedOption.value)}
                >

                    {props.label != 'Select Year' ?
                        (<>
                            <option value="" />
                            {props.userList.map((user, index) => (
                                <option key={index} value={user._id}>{user.name + "(" + user.role + ")" + "(" + user.email + ")"}</option>
                            ))}

                        </>) :
                        (<>
                            <option value={props.defaultValue}>{props.defaultValue}</option>
                            {props.userList.map((user, index) => (
                                user.year != props.defaultValue ?
                                    <option key={index} value={user.year}>{user.year}</option>
                                    : null
                            ))}
                        </>)
                    }
                </Select>
            </FormControl>

        </form>
    );
};

Dropdown.propTypes = {
    onChange: PropTypes.func,
};

Dropdown.defaultProps = {
    onChange: () => { },
};

export default withStyles(styles)(Dropdown);