import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';

// Component styles
import styles from './styles';

// Material helpers
import { Button, Grid, Typography, withStyles } from '@material-ui/core';

import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root}>
        <Typography variant="h6" className={classes.color}>{children}</Typography>
        {/* {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton}>
            <CloseIcon />
          </IconButton>
        ) : null} */}
      </MuiDialogTitle>
    );
  });

const DialogContent = withStyles(theme => ({
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
}))(MuiDialogActions);


class ModalDialog extends Component{
    constructor(props) {
        super(props);
        this.state = {
            open:props.open,
            selectedDistributor:'',
        }
        this.handleClose = this.handleClose.bind(this);
    }

    componentWillMount(){
        const authType = localStorage.getItem('authType');
        this.setState({
            authType:authType
        })
    }
    handleClose = () => {
        this.setState({ open: false});
    }
    componentWillReceiveProps(props){
        this.setState({
            open: props.open,
           // selectedDistributor:''
        });
    }
    handleFieldChange(feild,value){
        this.setState({
            [feild]: value
          });
        this.props.handleFieldChange(feild,value);
    }
    handleSellDevive(){
        this.props.handleSellDevive();

    }
    render(){
        const { classes, className,distributorList,deviceInfo} = this.props;
        const {open, selectedDistributor,authType} = this.state;
        const isValid = selectedDistributor == '' ? false :true;
        let deviceFullInfo={}
        if(authType === 'MANUFACTURER' || authType === 'ADMIN'){
        deviceFullInfo = deviceInfo.deviceDetail;
        }else{
        deviceFullInfo =  deviceInfo.deviceDetail.deviceDetails ?  deviceInfo.deviceDetail.deviceDetails[0]:{};
        }
        return (
            <Dialog
            //onClose={this.handleClose}
            //open={this.state.open}
	        open={open}
            fullWidth={true}
            maxWidth = {'xs'}
            >
                <div className={classes.bgColor}>
                    <DialogTitle id="customized-dialog-title" >
                        <CloseIcon className={classes.closeButton} onClick={this.handleClose}/>
                        {deviceFullInfo.deviceName}
                    </DialogTitle>
                </div>
                
                <DialogContent dividers>
                    <Typography>
                        {this.state.authType === 'MANUFACTURER'?
                            <h4>Select Distributor </h4>
                            :this.state.authType === 'DISTRIBUTOR'?
                            <h4>Select Distributor or Retailer </h4>
                            :this.state.authType === 'RETAILER'?
                            <h4>Select Doctor or User </h4>
                            :this.state.authType === 'DOCTOR'?
                            <h4>Select User </h4>
                            :null
                        }

                        <select className={classes.distributorDropdown}
                        onChange={
                            event =>this.handleFieldChange('selectedDistributor', event.target.value)
                        }
                        >
                            {this.state.authType === 'MANUFACTURER'?
                                <option value="">Select Distributor </option>
                                :this.state.authType === 'DISTRIBUTOR'?
                                <option value="">Select Distributor or Retailer </option>
                                :this.state.authType === 'RETAILER'?
                                <option value="">Select Doctor or User</option>
                                :this.state.authType === 'DOCTOR'?
                                <option value="">Select User  </option>:null
                            }
                            {
                            distributorList.map((dis,index) => (
                                <option key={index} value={dis._id}>{dis.name} ({dis.role}) ({dis.email})</option>
                            ))}
                        </select>
                    </Typography>
                </DialogContent>
                
                <DialogActions>
                    <Button disabled={!isValid} color="primary" onClick=
                    {event =>this.handleSellDevive('selectedDistributor', event.target.value)}
                    >
                        Sell
                    </Button>
                    <Button color="primary" onClick={this.handleClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }                  
};

ModalDialog.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  handleFieldChange: PropTypes.func,
  handleSellDevive: PropTypes.func
};

ModalDialog.defaultProps = {
    selectedDistributor: '',
    handleFieldChange:()=>{},
    handleSellDevive:()=>{},
  };

export default withStyles(styles)(ModalDialog);
