export default theme => ({
    root: {
        paddingLeft:theme.spacing.unit * 3,
        paddingRight:theme.spacing.unit * 3,
        paddingTop:theme.spacing.unit * 3,
        [theme.breakpoints.down('xs')]:{
            padding: theme.spacing.unit * 2
        }
    },
    portletHeader:{
        height: '40px',
        [theme.breakpoints.down('xs')]: {
            display: 'block',
            paddingLeft: theme.spacing.unit * 2,
            paddingTop: theme.spacing.unit * 1,
            paddingBottom: theme.spacing.unit * 5
        },
    },
    portletContent:{
        background: '#DCDCDC',
        [theme.breakpoints.down('xs')]: {
            paddingLeft: theme.spacing.unit * 2,
            paddingRight: theme.spacing.unit * 2
        },
    },
    portletTypoContent:{
        [theme.breakpoints.down('xs')]: {
            fontSize : '11px'
        }
    },
    createdDate:{
        textAlign : 'right',
        [theme.breakpoints.down('xs')]: {
            textAlign : 'left',
            fontSize : '10ssspx',
        }
    },
    deviceIdPortal:{
        [theme.breakpoints.down('xs')]: {
            fontSize : '11px',
            fontWeight : 700
        }
    }
});
  